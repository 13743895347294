import axios from "axios";
import { auth } from "@/firebase";

export default class Files {
  remoteUrl = "https://us-central1-api-faker.cloudfunctions.net/";
  //remoteUrl = "http://localhost:5001/api-faker/us-central1/";

  // Fetch an id token from Firebase for API authentication
  async getToken() {
    const user = auth.currentUser;
    const response = await user.getIdToken(false);
    return response;
  }

  // Download file using cloud function
  async downloadFile(projectPath, fileKey, count) {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "get",
      url:
        this.remoteUrl + "files/" + projectPath + "/" + fileKey + "/" + count,
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment,
      },
    });
  }
}
