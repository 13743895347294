<template>
  <hierarchical-index
    :dbref="dbref"
    :title="title"
    :new-button-label="newButtonLabel"
    :fields="fields"
  >
    <template #cell(size)="data">
      <span v-if="!data.item.isFolder">{{ formatSize(data.item.size) }}</span>
    </template>
  </hierarchical-index>
</template>

<script>
import HierarchicalIndex from "@/components/HierarchicalIndex";

export default {
  name: "Data",
  inject: ["notyf"],
  components: {
    HierarchicalIndex,
  },
  data() {
    return {
      dbref: "data",
      title: "Data",
      newButtonLabel: "New Data Store",
      fields: [
        {
          key: "icon",
          label: "",
          sortable: true,
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "description",
          label: "Description",
          sortable: true,
        },
        {
          key: "size",
          label: "Size",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
    };
  },
  methods: {
    getDataSize(data) {
      if (!data) {
        data = {
          records: [],
        };
      }
      const json = JSON.stringify(data);
      const size = (json.length - 14) / 1024;
      return size.toFixed(2);
    },
    formatSize(size) {
      if (isNaN(size)) {
        return "0 B";
      } else if (Number(size) < 1024) {
        return size + " B";
      } else if (Number(size) >= 1024 && Number(size) < 1048576) {
        return Number(size / 1024).toFixed(2) + " KiB";
      } else {
        return Number(size / 1048576).toFixed(2) + " MiB";
      }
    },
  },
};
</script>
