var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"sidebar",attrs:{"id":"sidebar"}},[_c('simplebar',{attrs:{"data-simplebar-auto-hide":"false"}},[_c('div',{staticClass:"sidebar-content js-simplebar"},[_c('b-link',{class:_vm.logoClasses,attrs:{"to":{ name: 'dashboard' }}},[_c('img',{attrs:{"src":_vm.logoSrc,"height":_vm.logoHeight}})]),_c('ul',{staticClass:"sidebar-nav"},[_c('li',{class:{ 'sidebar-item': true, active: _vm.route === 'dashboard' }},[_c('b-link',{staticClass:"sidebar-link",attrs:{"to":{ name: 'dashboard' }}},[_c('i',{staticClass:"align-middle fas fa-chart-area"}),_c('span',{staticClass:"align-middle"},[_vm._v("Dashboard")])])],1),_c('li',{class:{ 'sidebar-item': true, active: _vm.route === 'schemas' }},[_c('b-link',{staticClass:"sidebar-link",attrs:{"to":{ name: 'schemas' }}},[_c('i',{staticClass:"align-middle fas fa-table"}),_c('span',{staticClass:"align-middle"},[_vm._v("Schemas")])])],1),_c('li',{class:{
            'sidebar-item': true,
            active:
              _vm.route === 'apis' ||
              _vm.route === 'credentials' ||
              _vm.route === 'logs' ||
              _vm.route === 'test',
          }},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.apis",modifiers:{"apis":true}}],staticClass:"sidebar-link collapsed",attrs:{"data-toggle":"collapse"}},[_c('i',{staticClass:"align-middle fas fa-server"}),_c('span',{staticClass:"align-middle"},[_vm._v("APIs")])]),_c('b-collapse',{staticClass:"sidebar-dropdown list-unstyled",attrs:{"id":"apis","tag":"ul","data-parent":"#sidebar"},model:{value:(_vm.apisOpen),callback:function ($$v) {_vm.apisOpen=$$v},expression:"apisOpen"}},[_c('li',{class:{ 'sidebar-item': true, active: _vm.route === 'apis' }},[_c('b-link',{staticClass:"sidebar-link",attrs:{"to":{ name: 'apis' }}},[_vm._v("Configure")])],1),_c('li',{class:{
                'sidebar-item': true,
                active: _vm.route === 'credentials',
              }},[_c('b-link',{staticClass:"sidebar-link",attrs:{"to":{ name: 'credentials' }}},[_vm._v("Credentials")])],1),_c('li',{class:{ 'sidebar-item': true, active: _vm.route === 'logs' }},[_c('b-link',{staticClass:"sidebar-link",attrs:{"to":{ name: 'logs' }}},[_vm._v("Logs")])],1),_c('li',{class:{ 'sidebar-item': true, active: _vm.route === 'test' }},[_c('b-link',{staticClass:"sidebar-link",attrs:{"to":{ name: 'test' }}},[_vm._v("Test")])],1)])],1),_c('li',{class:{ 'sidebar-item': true, active: _vm.route === 'files' }},[_c('b-link',{staticClass:"sidebar-link",attrs:{"to":{ name: 'files' }}},[_c('i',{staticClass:"align-middle fas fa-file"}),_c('span',{staticClass:"align-middle"},[_vm._v("Flat Files")])])],1),_c('li',{class:{ 'sidebar-item': true, active: _vm.route === 'data' }},[_c('b-link',{staticClass:"sidebar-link",attrs:{"to":{ name: 'data' }}},[_c('i',{staticClass:"align-middle fas fa-database"}),_c('span',{staticClass:"align-middle"},[_vm._v("Data Stores")])])],1),_c('li',{class:{
            'sidebar-item': true,
            active: _vm.route === 'users' || _vm.route === 'roles',
          }},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.users",modifiers:{"users":true}}],staticClass:"sidebar-link collapsed",attrs:{"data-toggle":"collapse"}},[_c('i',{staticClass:"align-middle fas fa-user"}),_c('span',{staticClass:"align-middle"},[_vm._v("Users")])]),_c('b-collapse',{staticClass:"sidebar-dropdown list-unstyled",attrs:{"id":"users","tag":"ul","data-parent":"#sidebar"},model:{value:(_vm.usersOpen),callback:function ($$v) {_vm.usersOpen=$$v},expression:"usersOpen"}},[_c('li',{class:{ 'sidebar-item': true, active: _vm.route === 'users' }},[_c('b-link',{staticClass:"sidebar-link",attrs:{"to":{ name: 'users' }}},[_vm._v("Users")])],1),_c('li',{class:{
                'sidebar-item': true,
                active: _vm.route === 'roles',
              }},[_c('b-link',{staticClass:"sidebar-link",attrs:{"to":{ name: 'roles' }}},[_vm._v("Roles")])],1)])],1),_c('li',{class:{
            'sidebar-item': true,
            active:
              _vm.route === 'helpdesk' ||
              _vm.route === 'helpdesk-inbox' ||
              _vm.route.startsWith('helpdesk-shared'),
          }},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.helpdesk",modifiers:{"helpdesk":true}}],staticClass:"sidebar-link collapsed",attrs:{"data-toggle":"collapse"}},[_c('i',{staticClass:"align-middle fas fa-headset"}),_c('span',{staticClass:"align-middle"},[_vm._v("Helpdesk")])]),_c('b-collapse',{staticClass:"sidebar-dropdown list-unstyled",attrs:{"id":"helpdesk","tag":"ul","data-parent":"#sidebar"},model:{value:(_vm.helpdeskOpen),callback:function ($$v) {_vm.helpdeskOpen=$$v},expression:"helpdeskOpen"}},[_c('li',{class:{ 'sidebar-item': true, active: _vm.route === 'helpdesk' }},[_c('b-link',{staticClass:"sidebar-link",attrs:{"to":{ name: 'helpdesk' }}},[_vm._v("Overview")])],1),_c('li',{class:{
                'sidebar-item': true,
                active: _vm.route === 'helpdesk-inbox',
              }},[_c('b-link',{staticClass:"sidebar-link",attrs:{"to":{ name: 'helpdesk-inbox' }}},[_vm._v("Inbox")])],1),_vm._l((_vm.sharedMailboxes),function(mailbox,index){return _c('li',{key:index,class:{
                'sidebar-item': true,
                active: _vm.route === ("helpdesk-shared-" + (mailbox.slug)),
              }},[_c('b-link',{staticClass:"sidebar-link",attrs:{"to":{
                  name: 'helpdesk-shared',
                  params: { slug: mailbox.slug },
                }}},[_vm._v(_vm._s(mailbox.name))]),(mailbox.count > 0)?_c('span',{staticClass:"badge badge-sidebar-primary"},[_vm._v(_vm._s(mailbox.count))]):_vm._e()],1)})],2)],1),_c('li',{class:{ 'sidebar-item': true, active: _vm.route === 'kb' }},[_c('b-link',{staticClass:"sidebar-link",attrs:{"to":{ name: 'kb' }}},[_c('i',{staticClass:"align-middle fas fa-book"}),_c('span',{staticClass:"align-middle"},[_vm._v("Knowledgebase")])])],1),_c('li',{class:{
            'sidebar-item': true,
            active: _vm.route === 'settings' || _vm.route === 'settings-helpdesk',
          }},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.settings",modifiers:{"settings":true}}],staticClass:"sidebar-link collapsed",attrs:{"data-toggle":"collapse"}},[_c('i',{staticClass:"align-middle fas fa-cog"}),_c('span',{staticClass:"align-middle"},[_vm._v("Settings")])]),_c('b-collapse',{staticClass:"sidebar-dropdown list-unstyled",attrs:{"id":"settings","tag":"ul","data-parent":"#sidebar"},model:{value:(_vm.settingsOpen),callback:function ($$v) {_vm.settingsOpen=$$v},expression:"settingsOpen"}},[_c('li',{class:{ 'sidebar-item': true, active: _vm.route === 'settings' }},[_c('b-link',{staticClass:"sidebar-link",attrs:{"to":{ name: 'settings' }}},[_vm._v("General")])],1),_c('li',{class:{
                'sidebar-item': true,
                active: _vm.route === 'settings-helpdesk',
              }},[_c('b-link',{staticClass:"sidebar-link",attrs:{"to":{ name: 'settings-helpdesk' }}},[_vm._v("Helpdesk")])],1)])],1)])],1),_c('div',{class:{
        'sidebar-item': true,
        'sidebar-state-toggle': true,
        'text-center': _vm.sidebarState === 'collapsed',
        'text-right': _vm.sidebarState === 'open',
      }},[_c('b-link',{staticClass:"sidebar-link",on:{"click":_vm.toggleState}},[_c('i',{class:{
            fas: true,
            'fa-angle-double-right': _vm.sidebarState === 'collapsed',
            'fa-angle-double-left': _vm.sidebarState === 'open',
            'mr-0': true,
          }})])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }