<template>
  <b-container fluid class="overflow-hidden p-2">
    <apexchart
      type="line"
      height="300"
      :options="chartOptions"
      :series="series"
      class="mt-3"
      v-if="loaded"
    ></apexchart>
  </b-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
//import firebase from "firebase";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import VueApexCharts from "vue-apexcharts";
export default {
  name: "UserStatsWidget",
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    widget: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      stats: {},
      loaded: false,
      series: [
        {
          name: "Enrolled",
          data: [28, 29, 33, 36, 32, 32, 33],
        },
        {
          name: "Subscribed",
          data: [12, 11, 14, 18, 17, 13, 13],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#77B6EA", "#545454"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: [],
          title: {
            text: "Date",
            style: {
              color: "#a9acb3",
            },
          },
          labels: {
            style: {
              colors: "#a9acb3",
            },
          },
        },
        yaxis: {
          title: {
            text: "Users",
            style: {
              color: "#a9acb3",
            },
          },
          min: 0,
          max: 10,
          labels: {
            style: {
              colors: "#a9acb3",
            },
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
    };
  },
  watch: {
    stats: {
      immediate: true,
      handler(stats) {
        if (!("enrolled" in stats)) {
          this.stats.enrolled = {};
        }
        if (!("upgraded" in stats)) {
          this.stats.upgraded = {};
        }
      },
    },
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
  },
  methods: {
    ...mapMutations(["setUser", "setProfile"]),
  },
  mounted() {
    // Adjust colors for theme
    const theme = localStorage.getItem("theme");
    if (theme === "light") {
      this.chartOptions.xaxis.labels.style.colors = "#6c757d";
      this.chartOptions.xaxis.title.style.color = "#6c757d";
      this.chartOptions.yaxis.labels.style.colors = "#6c757d";
      this.chartOptions.yaxis.title.style.color = "#6c757d";
    }
    this.bindObject("stats/users", null, "stats").then(() => {
      let labels = [
        ...new Set([
          ...Object.keys(this.stats.enrolled),
          ...Object.keys(this.stats.upgraded),
        ]),
      ].sort();
      let enrolledData = [];
      let subscribedData = [];
      // Add enrolled and upgraded stats
      labels.forEach((label, i) => {
        // Add enrolled
        if (label in this.stats.enrolled) {
          enrolledData[i] = this.stats.enrolled[label];
        }
        // Add subscribed
        if (label in this.stats.upgraded) {
          subscribedData[i] = this.stats.upgraded[label];
        }
      });
      // Update chart data
      this.chartOptions.xaxis.categories = [...labels];
      this.series[0].data = [...enrolledData];
      this.series[1].data = [...subscribedData];
      this.loaded = true;
    });
  },
};
</script>

<style scoped>
.card-deck {
  margin-left: 2.5px !important;
  margin-right: 2.5px !important;
}
.card {
  margin-left: 2.5px !important;
  margin-right: 2.5px !important;
}
.card-shadow {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -4px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.card-title {
  text-align: center;
}
.card-footer {
  font-size: 60%;
  font-weight: 700;
  height: 54px;
}
</style>
