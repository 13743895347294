<template>
  <mailbox
    :name="mailbox.name"
    :mailbox-key="key"
    :mailbox-slug="slug"
    :tickets="tickets"
  ></mailbox>
</template>

<script>
import { db } from "../../firebase";
import { mapState, mapMutations } from "vuex";
//import firebase from "firebase";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import Mailbox from "@/components/Mailbox";

export default {
  name: "SharedInbox",
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    mailbox: Mailbox,
  },
  data() {
    return {
      key: null,
      slug: null,
      mailbox: {
        name: "Shared",
      },
      mailboxTarget: {},
      tickets: [],
    };
  },
  watch: {
    "$route.params.slug": {
      immediate: true,
      handler(slug) {
        if (slug) {
          const ref = db
            .ref("helpdesk/mailboxes")
            .orderByChild("slug")
            .equalTo(slug);
          this.$rtdbBind("mailboxTarget", ref);
        }
      },
    },
    mailboxTarget: {
      immediate: false,
      handler(target) {
        if (Object.keys(target).length > 0) {
          this.key = Object.keys(target)[0];
          this.bindObject("helpdesk/mailboxes", this.key, "mailbox");
          this.bindObject("helpdesk/tickets", this.key, "tickets");
        }
      },
    },
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    env() {
      return window.envPrefix;
    },
    userUid() {
      return this.userProfile.uid;
    },
  },
  methods: {
    ...mapMutations(["setUser", "setProfile"]),
  },
};
</script>

<style scoped></style>
