<template>
  <schema-editor
    :schema="schema"
    :sources="sources"
    v-on:save-and-exit="create"
  ></schema-editor>
</template>

<script>
import { mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import SchemaEditor from "@/components/SchemaEditor";

export default {
  name: "SchemaNew",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    SchemaEditor,
  },
  data() {
    return {
      project: {},
      schema: {
        name: "",
        description: "",
        type: "fake",
        source: "",
        fields: [],
      },
      sources: [],
      root: ["folders", "schemas", ""],
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    userUid() {
      return this.userProfile.uid;
    },
  },
  watch: {
    userUid: {
      immediate: true,
      handler(uid) {
        this.bindObject("selected", uid, "project");
      },
    },
    project: {
      immediate: true,
      handler(project) {
        if (project.objectID) {
          this.root[2] = project.objectID;
          console.log("Project", project.objectID, this.$route.query.path);
          if (this.$route.query.path !== undefined) {
            this.setRootFromPath(this.$route.query.path);
          }
          this.bindObject("data", project.objectID, "sources");
        }
      },
    },
  },
  methods: {
    create() {
      let schema = { ...this.schema };
      schema.path = this.root.join("/");

      this.createObjectKey("schemas/" + this.project.objectID)
        .then((key) => {
          Promise.all([
            this.updateObject("schemas/" + this.project.objectID, key, schema),
            this.updateObject(this.root.join("/"), key, schema),
          ])
            .then(() => {
              this.notyf.success("Schema created successfully");
              this.navigate("schemas-edit", { id: key });
            })
            .catch((error) => {
              console.log(error);
              this.notyf.error("An error occurred while saving the schema");
            });
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error("An error occurred while saving the schema");
        });
    },
    async setRootFromPath(routePath) {
      console.log("Route Path: " + routePath);
      const segments = routePath.split("/");
      let root = ["folders", "schemas", this.project.objectID];
      for (const segment of segments) {
        const snapshot = await this.getObjectSnapshot(
          root.join("/"),
          null,
          true
        );
        const folder = snapshot.val();
        console.log("Folder", folder);
        if ("folders" in folder) {
          Object.keys(folder.folders).forEach((subkey) => {
            const subfolder = folder.folders[subkey];
            if (subfolder.slug === segment.toLowerCase()) {
              root.push("folders", subkey);
            }
          });
        }
      }
      this.root = [...root, "items"];
    },
  },
};
</script>

<style lang="css" scoped>
.field-card {
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 10%) !important;
}

.no-body-margin {
  margin-left: -20px;
  margin-right: -20px;
  width: 107.5%;
}

.card-title-button {
  margin-top: -5px;
}

.list-header-button {
  width: 107%;
  margin-left: -16px !important;
  margin-right: -16px !important;
  text-align: left;
}

.list-method {
  padding-top: 0;
  padding-bottom: 0;
}

.prism-editor {
  height: 500px;
  border: 1px solid #545968;
  border-radius: 0.25rem;
  background: #363d4f;
}
</style>
