<template>
  <nav id="sidebar" class="sidebar">
    <simplebar data-simplebar-auto-hide="false">
      <div class="sidebar-content js-simplebar">
        <b-link :class="logoClasses" :to="{ name: 'dashboard' }">
          <img :src="logoSrc" :height="logoHeight" />
        </b-link>

        <ul class="sidebar-nav">
          <li :class="{ 'sidebar-item': true, active: route === 'dashboard' }">
            <b-link class="sidebar-link" :to="{ name: 'dashboard' }">
              <i class="align-middle fas fa-chart-area"></i>
              <span class="align-middle">Dashboard</span>
              <!--<span class="badge badge-sidebar-primary">5</span>-->
            </b-link>
          </li>
          <li :class="{ 'sidebar-item': true, active: route === 'schemas' }">
            <b-link class="sidebar-link" :to="{ name: 'schemas' }">
              <i class="align-middle fas fa-table"></i>
              <span class="align-middle">Schemas</span>
            </b-link>
          </li>
          <li
            :class="{
              'sidebar-item': true,
              active:
                route === 'apis' ||
                route === 'credentials' ||
                route === 'logs' ||
                route === 'test',
            }"
          >
            <a
              v-b-toggle.apis
              data-toggle="collapse"
              class="sidebar-link collapsed"
            >
              <i class="align-middle fas fa-server"></i>
              <span class="align-middle">APIs</span>
            </a>
            <b-collapse
              id="apis"
              v-model="apisOpen"
              tag="ul"
              class="sidebar-dropdown list-unstyled"
              data-parent="#sidebar"
            >
              <li :class="{ 'sidebar-item': true, active: route === 'apis' }">
                <b-link class="sidebar-link" :to="{ name: 'apis' }"
                  >Configure</b-link
                >
              </li>
              <li
                :class="{
                  'sidebar-item': true,
                  active: route === 'credentials',
                }"
              >
                <b-link class="sidebar-link" :to="{ name: 'credentials' }"
                  >Credentials</b-link
                >
              </li>
              <li :class="{ 'sidebar-item': true, active: route === 'logs' }">
                <b-link class="sidebar-link" :to="{ name: 'logs' }"
                  >Logs</b-link
                >
              </li>
              <li :class="{ 'sidebar-item': true, active: route === 'test' }">
                <b-link class="sidebar-link" :to="{ name: 'test' }"
                  >Test</b-link
                >
              </li>
            </b-collapse>
          </li>
          <li :class="{ 'sidebar-item': true, active: route === 'files' }">
            <b-link class="sidebar-link" :to="{ name: 'files' }">
              <i class="align-middle fas fa-file"></i>
              <span class="align-middle">Flat Files</span>
            </b-link>
          </li>
          <li :class="{ 'sidebar-item': true, active: route === 'data' }">
            <b-link class="sidebar-link" :to="{ name: 'data' }">
              <i class="align-middle fas fa-database"></i>
              <span class="align-middle">Data Stores</span>
            </b-link>
          </li>
          <li
            :class="{
              'sidebar-item': true,
              active: route === 'users' || route === 'roles',
            }"
          >
            <a
              v-b-toggle.users
              data-toggle="collapse"
              class="sidebar-link collapsed"
            >
              <i class="align-middle fas fa-user"></i>
              <span class="align-middle">Users</span>
            </a>
            <b-collapse
              id="users"
              v-model="usersOpen"
              tag="ul"
              class="sidebar-dropdown list-unstyled"
              data-parent="#sidebar"
            >
              <li :class="{ 'sidebar-item': true, active: route === 'users' }">
                <b-link class="sidebar-link" :to="{ name: 'users' }"
                  >Users</b-link
                >
              </li>
              <li
                :class="{
                  'sidebar-item': true,
                  active: route === 'roles',
                }"
              >
                <b-link class="sidebar-link" :to="{ name: 'roles' }"
                  >Roles</b-link
                >
              </li>
            </b-collapse>
          </li>
          <li
            :class="{
              'sidebar-item': true,
              active:
                route === 'helpdesk' ||
                route === 'helpdesk-inbox' ||
                route.startsWith('helpdesk-shared'),
            }"
          >
            <a
              v-b-toggle.helpdesk
              data-toggle="collapse"
              class="sidebar-link collapsed"
            >
              <i class="align-middle fas fa-headset"></i>
              <span class="align-middle">Helpdesk</span>
            </a>
            <b-collapse
              id="helpdesk"
              v-model="helpdeskOpen"
              tag="ul"
              class="sidebar-dropdown list-unstyled"
              data-parent="#sidebar"
            >
              <li
                :class="{ 'sidebar-item': true, active: route === 'helpdesk' }"
              >
                <b-link class="sidebar-link" :to="{ name: 'helpdesk' }"
                  >Overview</b-link
                >
              </li>
              <li
                :class="{
                  'sidebar-item': true,
                  active: route === 'helpdesk-inbox',
                }"
              >
                <b-link class="sidebar-link" :to="{ name: 'helpdesk-inbox' }"
                  >Inbox</b-link
                >
              </li>
              <li
                :class="{
                  'sidebar-item': true,
                  active: route === `helpdesk-shared-${mailbox.slug}`,
                }"
                v-for="(mailbox, index) in sharedMailboxes"
                :key="index"
              >
                <b-link
                  class="sidebar-link"
                  :to="{
                    name: 'helpdesk-shared',
                    params: { slug: mailbox.slug },
                  }"
                  >{{ mailbox.name }}</b-link
                >
                <span
                  v-if="mailbox.count > 0"
                  class="badge badge-sidebar-primary"
                  >{{ mailbox.count }}</span
                >
              </li>
            </b-collapse>
          </li>
          <li :class="{ 'sidebar-item': true, active: route === 'kb' }">
            <b-link class="sidebar-link" :to="{ name: 'kb' }">
              <i class="align-middle fas fa-book"></i>
              <span class="align-middle">Knowledgebase</span>
            </b-link>
          </li>
          <li
            :class="{
              'sidebar-item': true,
              active: route === 'settings' || route === 'settings-helpdesk',
            }"
          >
            <a
              v-b-toggle.settings
              data-toggle="collapse"
              class="sidebar-link collapsed"
            >
              <i class="align-middle fas fa-cog"></i>
              <span class="align-middle">Settings</span>
            </a>
            <b-collapse
              id="settings"
              v-model="settingsOpen"
              tag="ul"
              class="sidebar-dropdown list-unstyled"
              data-parent="#sidebar"
            >
              <li
                :class="{ 'sidebar-item': true, active: route === 'settings' }"
              >
                <b-link class="sidebar-link" :to="{ name: 'settings' }"
                  >General</b-link
                >
              </li>
              <li
                :class="{
                  'sidebar-item': true,
                  active: route === 'settings-helpdesk',
                }"
              >
                <b-link class="sidebar-link" :to="{ name: 'settings-helpdesk' }"
                  >Helpdesk</b-link
                >
              </li>
            </b-collapse>
          </li>
        </ul>
      </div>
      <div
        :class="{
          'sidebar-item': true,
          'sidebar-state-toggle': true,
          'text-center': sidebarState === 'collapsed',
          'text-right': sidebarState === 'open',
        }"
      >
        <b-link class="sidebar-link" @click="toggleState">
          <i
            :class="{
              fas: true,
              'fa-angle-double-right': sidebarState === 'collapsed',
              'fa-angle-double-left': sidebarState === 'open',
              'mr-0': true,
            }"
          ></i>
        </b-link>
      </div>
    </simplebar>
  </nav>
</template>

<script>
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";

export default {
  name: "Sidebar",
  mixins: [FirebaseMixin, UtilsMixin],
  props: {
    sidebarOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      sidebarState: "open",
      bind: [{ ref: "helpdesk/mailboxes", target: "mailboxes" }],
      mailboxes: [],
      logo: {
        light: {
          open: require("../assets/img/logo_light.png"),
          collapsed: require("../assets/img/logo_img_light.png"),
        },
        dark: {
          open: require("../assets/img/logo.png"),
          collapsed: require("../assets/img/logo_img.png"),
        },
      },
      apisOpen: false,
      usersOpen: false,
      helpdeskOpen: false,
      settingsOpen: false,
    };
  },
  components: {
    simplebar,
  },
  computed: {
    sidebarWidth() {
      return this.sidebarState === "collapsed" ? 68 : 260;
    },
    logoSrc() {
      return this.sidebarState === "collapsed"
        ? this.logo[this.theme].collapsed
        : this.logo[this.theme].open;
    },
    logoHeight() {
      return this.sidebarState === "collapsed" ? "30px" : "40px";
    },
    logoClasses() {
      const classes = ["sidebar-brand"];
      if (this.sidebarState === "collapsed") {
        classes.push("sidebar-brand-sm");
      }
      return classes;
    },
    theme() {
      return document.body.dataset["theme"];
    },
    route() {
      if ("slug" in this.$route.params) {
        return String(this.$route.name + "-" + this.$route.params.slug);
      } else {
        return String(this.$route.name);
      }
    },
    sharedMailboxes() {
      return this.mailboxes
        .map((box) => {
          box.count = Array.isArray(box.tickets) ? box.tickets.length : 0;
          return box;
        })
        .sort((a, b) => {
          a.order = "order" in a && !isNaN(a.order) ? a.order : 0;
          b.order = "order" in b && !isNaN(b.order) ? b.order : 0;
          return a.order - b.order;
        });
    },
  },
  watch: {
    route: {
      immediate: true,
      handler(route) {
        if (
          route === "apis" ||
          route === "credentials" ||
          route === "logs" ||
          route === "test"
        ) {
          this.apisOpen = true;
        }
        if (route === "users" || route === "roles") {
          this.usersOpen = true;
        }
        if (
          route === "helpdesk" ||
          route === "helpdesk-inbox" ||
          route.startsWith("helpdesk-shared")
        ) {
          this.helpdeskOpen = true;
        }
        if (route === "settings" || route === "settings-helpdesk") {
          this.settingsOpen = true;
        }
      },
    },
    sidebarOpen: {
      immediate: true,
      handler(state) {
        if (document.querySelector("#sidebar")) {
          if (state) {
            // Sidebar is open
            document.querySelector("#sidebar").style.marginLeft = "0px";
            document.querySelector("#main").style.width =
              "calc(100% - " + this.sidebarWidth + "px)";
          } else {
            // Sidebar is closed
            document.querySelector("#sidebar").style.marginLeft =
              this.sidebarWidth * -1 + "px";
            document.querySelector("#main").style.width = "100%";
          }
        }
      },
    },
  },
  methods: {
    toggleState() {
      if (this.sidebarState === "open") {
        this.sidebarState = "collapsed";
        document.body.dataset["sidebarBehavior"] = "compact";
        if (this.sidebarOpen) {
          // Sidebar is open
          document.querySelector("#sidebar").style.marginLeft = "0px";
          document.querySelector("#main").style.width =
            "calc(100% - " + this.sidebarWidth + "px)";
        } else {
          // Sidebar is closed
          document.querySelector("#sidebar").style.marginLeft =
            this.sidebarWidth * -1 + "px";
          document.querySelector("#main").style.width = "100%";
        }
      } else {
        this.sidebarState = "open";
        document.body.dataset["sidebarBehavior"] = "sticky";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-brand-sm {
  padding-left: 0.85rem;
}
.sidebar-state-toggle {
  position: fixed !important;
  bottom: 0;
  width: 260px;
}
body[data-sidebar-behavior="compact"] {
  .sidebar-state-toggle {
    width: 68px;
  }
}
body[data-theme="dark"] {
  .sidebar-state-toggle {
    background: #293042;
  }
}
body[data-theme="light"] {
  .sidebar-state-toggle {
    background: #fff;
  }
}
</style>
