import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    config: {
      algolia: {
        app_id: "",
        search_key: "",
      },
      stripe_publishable_key: "",
      api_url: "",
      github_client_id: "",
    },
    userProfile: {
      uid: "",
      email: "",
      name: "",
      profile: {
        avatar: "",
        firstName: "",
        lastName: "",
      },
      search_key: "",
    },
    isAuthenticated: false,
  },
  getters: {
    userDisplayName: (state) => {
      const firstName =
        "firstName" in state.userProfile.profile
          ? state.userProfile.profile.firstName
          : null;
      const lastName =
        "lastName" in state.userProfile.profile
          ? state.userProfile.profile.lastName
          : null;
      return firstName ? String(firstName + " " + lastName).trim() : "New User";
    },
    userAuthorName: (state) => {
      let name = "";
      if (
        "firstName" in state.userProfile.profile &&
        state.userProfile.profile.firstName.length > 0
      ) {
        name = state.userProfile.profile.firstName;
      }
      if (
        "lastName" in state.userProfile.profile &&
        state.userProfile.profile.lastName.length > 0
      ) {
        const initial = state.userProfile.profile.lastName.substring(0, 1);
        name += " " + initial + ".";
      }
      if (name.length > 0) {
        return name;
      } else {
        return "Anonymous";
      }
    },
    userAvatar: (state) => {
      try {
        const avatar = state.userProfile.profile.avatar;
        return String(avatar).startsWith("http")
          ? avatar
          : require("../assets/img/img_avatar.png");
      } catch (e) {
        return require("../assets/img/img_avatar.png");
      }
    },
    userPermissions: (state) => {
      try {
        JSON.stringify(state.userProfile);
        const permissions = state.userProfile.permissions;
        return { ...permissions };
      } catch (e) {
        console.log("Vuex: userPermissions: " + e.message);
        return {};
      }
    },
    stripeKey: (state) => {
      return state.config.stripe_publishable_key;
    },
    searchKey: (state) => {
      return state.userProfile.search_key;
    },
    searchConfig: (state) => {
      const config = { ...state.config };
      if ("private" in config && "algolia" in config.private) {
        return config.private.algolia;
      } else {
        return {};
      }
    },
  },
  mutations: {
    setConfig(state, payload) {
      for (const key in payload) {
        state.config[key] = payload[key];
      }
    },
    setUser(state, payload) {
      for (const key in payload) {
        state.userProfile[key] = payload[key];
      }
    },
    setProfile(state, payload) {
      for (const key in payload) {
        state.userProfile.profile[key] = payload[key];
      }
    },
    setAuthStatus(state, payload) {
      state.isAuthenticated = payload === true ? true : false;
    },
    setSearchKey(state, payload) {
      state.userProfile.search_key = payload;
    },
  },
  actions: {},
  modules: {},
});
