// Global
import "@/modules/theme";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
import { notyfConfig } from "@/modules/notyf";

import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import InstantSearch from "vue-instantsearch";
import VueTheMask from "vue-the-mask";

import "bootstrap-vue/dist/bootstrap-vue.css";
import "@fortawesome/fontawesome-free/css/all.css";
Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(InstantSearch);
Vue.use(VueTheMask);

// Handle different environments
if (
  window.location.host === "admin.apifaker.dev" ||
  window.location.host === "api-faker-admin.web.app" ||
  window.location.host === "api-faker-admin.firebaseapp.com"
) {
  window.environment = "production";
} else {
  window.environment = "sandbox";
}

const vm = new Vue({
  router,
  store,
  provide: () => {
    return {
      notyf: new Notyf(notyfConfig),
    };
  },
  render: (h) => h(App),
}).$mount("#app");

window.vm = vm;
