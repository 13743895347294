<template>
  <div class="wrapper">
    <b-container fluid v-if="loading">
      <b-row>
        <b-col class="d-flex justify-content-center">
          <loader class="my-5" color="rgba(255, 152, 0, 0.75)"></loader>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="full-height" fluid v-if="!loading">
      <b-row class="full-height">
        <b-col class="full-height">
          <div class="card card-shadow mt-3">
            <b-container class="full-height" fluid>
              <b-row>
                <b-col class="text-left px-0">
                  <div class="card-body">
                    <h5 class="card-title">{{ article.name }}</h5>
                    <small class="text-muted"
                      ><b>Location:</b> {{ friendlyPath }}</small
                    >
                  </div>
                </b-col>
                <b-col class="text-right px-0">
                  <div class="card-body">
                    <b-button
                      variant="success"
                      :disabled="saving"
                      size="sm"
                      class="mr-1"
                      @click="update"
                    >
                      <span v-if="saving">
                        <b-spinner small></b-spinner>
                        <span class="sr-only">Saving...</span>
                      </span>
                      <span v-else>
                        <i class="far fa-save"></i>
                      </span>
                    </b-button>
                    <b-button
                      variant="danger"
                      @click="closeEditor"
                      size="sm"
                      class="mr-1"
                      ><i class="far fa-window-close"></i
                    ></b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row class="editor-row">
                <b-col class="full-height">
                  <!-- <ckeditor
                    :editor="editor"
                    v-model="article.text"
                    :config="editorConfig"
                  ></ckeditor> -->
                  <text-editor
                    placeholder="Article text goes here..."
                    v-model="article.text"
                    class="editor"
                  ></text-editor>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import Loader from "@/components/Loader";
import TextEditor from "@/components/TextEditor";
// import FirebaseUploadAdaptorPlugin from "@/helpers/FirebaseUploadAdapter";
// import CKEditor from "@ckeditor/ckeditor5-vue2";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "ArticleEditor",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    loader: Loader,
    TextEditor,
  },
  data() {
    return {
      bind: [{ ref: "articles", target: "articles" }],
      loading: false,
      saving: false,
      uploading: false,
      articleKey: null,
      article: {
        name: "",
        description: "",
        text: "",
      },
      images: {},
      image: null,
      path: [],
      friendlyPath: "",
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile", "config"]),
    ...mapGetters(["searchConfig"]),
  },
  watch: {
    path: {
      immediate: false,
      handler(path) {
        this.bindObject(path, null, "article");
      },
    },
  },
  methods: {
    closeEditor() {
      window.close();
    },
    update() {
      this.saving = true;
      const article = { ...this.article };
      if (this.path && this.path.length > 0) {
        this.updateObject(this.path, null, article)
          .then(() => {
            this.saving = false;
            this.notyf.success("Article saved successfully.");
          })
          .catch((error) => {
            this.saving = false;
            this.notyf.error(
              "An error occurred updating the article: " + error
            );
            console.error("Unable to update article", this.path, error);
          });
      }
      return true;
    },
    async setFriendlyPath(routePath) {
      console.log("Route Path: " + routePath);
      const segments = routePath.split("/");
      segments.pop();
      let root = ["articles"];
      let path = [];
      for (const segment of segments) {
        if (segment.startsWith("-")) {
          const snapshot = await this.getObjectSnapshot(
            root.join("/"),
            segment,
            true
          );
          const category = snapshot.val();
          root.push(segment, "articles");
          path.push(category.name);
        }
      }
      this.friendlyPath = "/" + path.join("/");
    },
  },
  created() {
    if (this.$route.params.path !== undefined) {
      this.path = this.$route.params.path;
      this.articleKey = this.path.split("/").pop();
      this.setFriendlyPath(this.path);
    }
  },
};
</script>

<style>
main {
  height: calc(100% - 70px);
}

#content {
  height: 100%;
}

.ck.ck-editor {
  height: calc(100% - 12px);
}

.ck-editor__main {
  height: calc(100% - 39px);
}

.ck-editor__editable_inline {
  height: 100%;
}
</style>

<style scoped>
.wrapper {
  max-width: 1000px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.full-height {
  height: 100%;
}

.card {
  height: calc(100% - 2rem);
}

.card-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -4px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.editor-row {
  height: calc(100% - 100px);
  margin-bottom: 0.75rem;
}

.editor {
  height: calc(100% - 50px);
}
</style>
