<template>
  <hierarchical-index
    :dbref="dbref"
    :title="title"
    :new-button-label="newButtonLabel"
    :fields="fields"
    :search-fields="searchFields"
  >
    <template #cell(type)="data">
      <span v-if="!data.item.isFolder">
        {{ data.item.type === "stored" ? "Stored & Fake Data" : "Fake Data" }}
      </span>
    </template>
  </hierarchical-index>
</template>

<script>
import HierarchicalIndex from "@/components/HierarchicalIndex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";

export default {
  name: "Schemas",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    HierarchicalIndex,
  },
  data() {
    return {
      dbref: "schemas",
      title: "Schema",
      newButtonLabel: "New Schema",
      fields: [
        {
          key: "icon",
          label: "",
          sortable: true,
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "description",
          label: "Description",
          sortable: true,
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      searchFields: ["name", "description", "type"],
    };
  },
};
</script>
