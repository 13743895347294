<template>
  <div>
    <b-card title="Request" class="card-wrapper" v-if="project.objectID">
      <b-container>
        <b-row>
          <b-col>
            <b-input-group id="request-url-bar">
              <template #prepend>
                <b-form-select
                  :options="methods"
                  v-model="request.method"
                  class="no-rounded-right method-select"
                  placeholder="Method"
                />
                <b-form-select
                  :options="endpointOptions"
                  v-model="request.endpoint"
                  class="rounded-0 endpoint-select"
                  placeholder="Endpoint"
                />
              </template>

              <b-form-input
                v-model="request.path"
                placeholder="Path"
                disabled
              ></b-form-input>

              <template #append>
                <b-button variant="primary" @click="send" :disabled="sending">
                  <b-spinner small v-if="sending"></b-spinner>
                  <span v-if="!sending">Send</span>
                </b-button>
              </template>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-tabs class="mt-3" content-class="mt-3" pills>
              <b-tab title="Params" active>
                <div class="mt-4 mb-2 d-flex justify-content-between">
                  <h5 class="text-muted">Query Params</h5>
                  <div>
                    <b-button variant="primary" size="sm" @click="addParam"
                      ><i class="fas fa-plus"></i> Add Param</b-button
                    >
                  </div>
                </div>
                <b-table class="mb-3" striped :items="params" :fields="fields">
                  <template #cell(key)="data">
                    <b-form-input
                      v-model="data.item.key"
                      placeholder="e.g. myQueryParam"
                    ></b-form-input>
                  </template>
                  <template #cell(value)="data">
                    <b-form-input
                      v-model="data.item.value"
                      placeholder="e.g. ParamValue"
                    ></b-form-input>
                  </template>
                  <template #cell(actions)="data">
                    <b-button
                      variant="danger"
                      size="sm"
                      @click="deleteParam(data.index)"
                      ><i class="far fa-trash-alt"></i
                    ></b-button>
                  </template>
                </b-table>
              </b-tab>
              <b-tab title="Authorization">
                <b-form-group label="Type">
                  <b-form-radio-group
                    v-model="request.authorization.type"
                    :options="[
                      { value: 'none', text: 'None' },
                      { value: 'saved', text: 'Saved' },
                      { value: 'key', text: 'API Key' },
                      { value: 'basic', text: 'Basic Auth' },
                      { value: 'bearer', text: 'Bearer Token' },
                      { value: 'oauth', text: 'OAuth' },
                    ]"
                  ></b-form-radio-group>
                </b-form-group>
                <b-form-group
                  label="Credentials"
                  v-if="request.authorization.type === 'saved'"
                >
                  <b-form-select
                    :options="credentialOptions"
                    v-model="request.authorization.credentials"
                    required
                  />
                </b-form-group>
                <b-form-group
                  label="API Header"
                  v-if="request.authorization.type === 'key'"
                >
                  <b-form-input
                    type="text"
                    placeholder="e.g. X-API-KEY"
                    v-model="request.authorization.api_header"
                    required
                    trim
                  />
                </b-form-group>
                <b-form-group
                  label="API Key"
                  v-if="request.authorization.type === 'key'"
                >
                  <b-form-input
                    type="text"
                    placeholder="e.g. MYKEY3344D2D"
                    v-model="request.authorization.api_key"
                    required
                    trim
                  />
                </b-form-group>
                <b-form-group
                  label="Username"
                  v-if="request.authorization.type === 'basic'"
                >
                  <b-form-input
                    type="text"
                    placeholder="e.g. my-user@domain.com"
                    v-model="request.authorization.username"
                    required
                    trim
                  />
                </b-form-group>
                <b-form-group
                  label="Password"
                  v-if="request.authorization.type === 'basic'"
                >
                  <b-form-input
                    type="password"
                    placeholder="e.g. hunter2"
                    v-model="request.authorization.password"
                    required
                    trim
                  />
                </b-form-group>
                <b-form-group
                  label="Bearer Token"
                  v-if="request.authorization.type === 'bearer'"
                >
                  <b-form-input
                    type="text"
                    placeholder="e.g. eyJhbGciOiJ..."
                    v-model="request.authorization.token"
                    required
                    trim
                  />
                </b-form-group>
              </b-tab>
              <b-tab title="Headers">
                <div class="mt-4 mb-2 d-flex justify-content-between">
                  <h5 class="text-muted">Headers</h5>
                  <div>
                    <b-button variant="primary" size="sm" @click="addHeader"
                      ><i class="fas fa-plus"></i> Add Header</b-button
                    >
                  </div>
                </div>
                <b-table
                  class="mb-3"
                  striped
                  :items="request.headers"
                  :fields="fields"
                >
                  <template #cell(key)="data">
                    <b-form-input
                      v-model="data.item.key"
                      placeholder="e.g. Content-Type"
                    ></b-form-input>
                  </template>
                  <template #cell(value)="data">
                    <b-form-input
                      v-model="data.item.value"
                      placeholder="e.g. application/json"
                    ></b-form-input>
                  </template>
                  <template #cell(actions)="data">
                    <b-button
                      variant="danger"
                      size="sm"
                      @click="deleteHeader(data.index)"
                      ><i class="far fa-trash-alt"></i
                    ></b-button>
                  </template>
                </b-table>
              </b-tab>
              <b-tab title="Body">
                <b-form-group label="Type">
                  <b-form-radio-group
                    v-model="request.body.type"
                    :options="[
                      { value: 'none', text: 'None' },
                      { value: 'json', text: 'JSON' },
                      { value: 'text', text: 'Text' },
                      { value: 'form-data', text: 'Form-Data' },
                      { value: 'graphql', text: 'GraphQL' },
                    ]"
                    @change="updateContentHeader"
                  ></b-form-radio-group>
                </b-form-group>
                <prism-editor
                  v-model="request.body.text"
                  language="json"
                  line-numbers
                  :highlight="jsonHighlighter"
                  class="prism-editor mt-1"
                  v-if="request.body.type === 'json'"
                ></prism-editor>
                <prism-editor
                  v-model="request.body.text"
                  language="none"
                  line-numbers
                  :highlight="textHighlighter"
                  class="prism-editor mt-1"
                  v-if="request.body.type === 'text'"
                ></prism-editor>
                <div v-if="request.body.type === 'form-data'">
                  <div class="mt-4 mb-2 d-flex justify-content-between">
                    <div>
                      <b-button variant="primary" size="sm" @click="addFormData"
                        ><i class="fas fa-plus"></i> Add Form Data</b-button
                      >
                    </div>
                  </div>
                  <b-table
                    class="mb-3"
                    striped
                    :items="request.body.formdata"
                    :fields="fields"
                  >
                    <template #cell(key)="data">
                      <b-form-input
                        v-model="data.item.key"
                        placeholder="e.g. myKey"
                      ></b-form-input>
                    </template>
                    <template #cell(value)="data">
                      <b-form-input
                        v-model="data.item.value"
                        placeholder="e.g. myValue"
                      ></b-form-input>
                    </template>
                    <template #cell(actions)="data">
                      <b-button
                        variant="danger"
                        size="sm"
                        @click="deleteFormData(data.index)"
                        ><i class="far fa-trash-alt"></i
                      ></b-button>
                    </template>
                  </b-table>
                </div>
                <b-container fluid v-if="request.body.type === 'graphql'">
                  <b-row>
                    <b-col>
                      <b-card no-body>
                        <b-card-title>Query</b-card-title>
                        <prism-editor
                          v-model="request.body.query"
                          language="graphql"
                          line-numbers
                          :highlight="graphqlHighlighter"
                          class="prism-editor"
                        ></prism-editor>
                      </b-card>
                    </b-col>
                    <b-col>
                      <b-card no-body>
                        <b-card-title>Variables</b-card-title>
                        <prism-editor
                          v-model="request.body.variables"
                          language="json"
                          line-numbers
                          :highlight="jsonHighlighter"
                          class="prism-editor"
                        ></prism-editor>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-container>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-container>
    </b-card>

    <b-card class="card-wrapper" v-if="project.objectID" no-body>
      <b-card-header class="pb-0"
        ><h4 class="card-title">
          Response
          <small class="float-right" v-if="showResponse"
            >Status:
            <span :class="'mr-1 text-' + responseStatusColor">{{
              response.status
            }}</span>
            Time:
            <span class="mr-1 text-primary">{{ response.time }} ms</span>
            Size:
            <span class="mr-1 text-primary">{{ response.size }} B</span></small
          >
        </h4></b-card-header
      >
      <b-card-body class="pt-0">
        <b-container v-if="!showResponse">
          <b-alert variant="info" show
            ><div class="alert-message">
              <i class="fas fa-info-circle"></i> Response will be displayed here
              once a request is sent.
            </div></b-alert
          >
        </b-container>
        <b-container v-else>
          <b-tabs class="mt-3" content-class="mt-3" pills>
            <b-tab title="Body">
              <prism-editor
                v-model="response.body"
                language="json"
                line-numbers
                :highlight="jsonHighlighter"
                class="prism-editor mt-1"
              ></prism-editor>
              <!-- <prism-editor
                v-model="request.body.text"
                language="none"
                line-numbers
                :highlight="textHighlighter"
                class="prism-editor mt-1"
                v-if="request.body.type === 'text'"
              ></prism-editor> -->
            </b-tab>
            <b-tab title="Headers">
              <div class="mt-4 mb-2 d-flex justify-content-between">
                <h5 class="text-muted">Headers</h5>
              </div>
              <b-table
                class="mb-3"
                striped
                :items="response.headers"
                :fields="fields"
              ></b-table>
            </b-tab>
          </b-tabs>
        </b-container>
      </b-card-body>
    </b-card>

    <b-card class="card-wrapper" v-else>
      <b-container>
        <b-alert variant="info" show
          ><div class="alert-message">
            <i class="fas fa-exclamation-circle"></i> Please select a project.
          </div></b-alert
        >
      </b-container>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import { DateTime } from "luxon";
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-json";
import "prismjs/components/prism-graphql";
import "prismjs/themes/prism-tomorrow.css";

export default {
  name: "Test",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    PrismEditor,
  },
  data() {
    return {
      sending: false,
      project: {},
      apis: [],
      credentials: {},
      methods: [
        { value: "get", text: "GET" },
        { value: "post", text: "POST" },
        { value: "put", text: "PUT" },
        { value: "patch", text: "PATCH" },
        { value: "delete", text: "DELETE" },
        { value: "options", text: "OPTIONS" },
      ],
      params: [],
      request: {
        method: "get",
        endpoint: null,
        authorization: {
          type: "none",
          credentials: null,
        },
        path: "",
        headers: [
          {
            key: "Content-Type",
            value: "application/json",
          },
          {
            key: "User-Agent",
            value: "API-Faker-Tester",
          },
          {
            key: "Accept",
            value: "*/*",
          },
          {
            key: "Accept-Encoding",
            value: "gzip, deflate, br",
          },
          {
            key: "Connection",
            value: "keep-alive",
          },
        ],
        body: {
          type: "none",
          text: "",
          formdata: [],
          query: "",
          variables: "",
        },
      },
      showResponse: false,
      response: {
        headers: [],
        status: null,
        time: null,
        body: null,
        size: null,
      },
      fields: [
        {
          key: "key",
          label: "Key",
          sortable: true,
        },
        {
          key: "value",
          label: "Value",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile", "config"]),
    ...mapGetters(["userDisplayName", "userAvatar"]),
    userUid() {
      return this.userProfile.uid;
    },
    apiUrl() {
      return this.config.api_url;
    },
    endpointOptions() {
      return [
        {
          text: "Endpoints",
          value: null,
        },
        ...this.apis.map((api) => {
          return {
            text: api.endpoint,
            value: api.endpoint,
          };
        }),
      ];
    },
    credentialOptions() {
      return Object.keys(this.credentials).map((key) => {
        return {
          text: this.credentials[key].name,
          value: key,
        };
      });
    },
    responseStatusColor() {
      const status = Number(this.response.status);
      if (status >= 200 && status <= 299) {
        return "success";
      } else if (status >= 400 && status <= 599) {
        return "danger";
      } else {
        return "primary";
      }
    },
  },
  watch: {
    userUid: {
      immediate: true,
      handler(uid) {
        this.bindObject("selected", uid, "project");
      },
    },
    project: {
      immediate: true,
      handler(project) {
        if (project.objectID) {
          this.bindObject("apis", project.objectID, "apis");
          this.bindObject("credentials", project.objectID, "credentials");
        }
      },
    },
    params: {
      immediate: false,
      deep: true,
      handler(params) {
        const usp = new URLSearchParams();
        if (Array.isArray(params)) {
          for (const param of params) {
            if (param.key) {
              const val = param.value ? param.value : "";
              usp.append(param.key, val);
            }
          }
        }
        this.request.path = "?" + usp.toString();
      },
    },
  },
  methods: {
    deleteLog(log) {
      const key = log.key;

      this.deleteObject("logs/" + this.project.objectID, key)
        .then(() => {
          this.notyf.success("Log deleted successfully.");
        })
        .catch((error) => {
          this.notyf.error("An error occurred deleting the log.");
          console.error("Log delete failed", key, error);
        });
    },
    formatDate(date) {
      const dt = DateTime.fromMillis(date);
      return dt.toLocaleString(DateTime.DATETIME_FULL);
    },
    addParam() {
      this.params.push({});
      this.$forceUpdate();
    },
    deleteParam(index) {
      this.params.splice(index, 1);
      this.$forceUpdate();
    },
    addHeader() {
      this.request.headers.push({});
      this.$forceUpdate();
    },
    deleteHeader(index) {
      this.request.headers.splice(index, 1);
      this.$forceUpdate();
    },
    addFormData() {
      this.request.body.formdata.push({});
      this.$forceUpdate();
    },
    deleteFormData(index) {
      this.request.body.formdata.splice(index, 1);
      this.$forceUpdate();
    },
    jsonHighlighter(code) {
      return highlight(code, languages.json);
    },
    graphqlHighlighter(code) {
      return highlight(code, languages.graphql);
    },
    textHighlighter(code) {
      return code;
    },
    updateContentHeader() {
      const type = this.request.body.type;
      const index = this.request.headers.findIndex(
        (header) => header.key.toLowerCase() === "content-type"
      );
      // console.log("Content-Type", type, index);
      switch (type) {
        case "json":
          if (index !== -1) {
            this.request.headers[index].value = "application/json";
          } else {
            this.request.headers.push({
              key: "Content-Type",
              value: "application/json",
            });
          }
          break;
        case "text":
          if (index !== -1) {
            this.request.headers[index].value = "text/plain";
          } else {
            this.request.headers.push({
              key: "Content-Type",
              value: "text/plain",
            });
          }
          break;
        case "form-data":
          if (index !== -1) {
            this.request.headers[index].value = "multipart/form-data";
          } else {
            this.request.headers.push({
              key: "Content-Type",
              value: "multipart/form-data",
            });
          }
          break;
        case "graphql":
          if (index !== -1) {
            this.request.headers[index].value = "application/json";
          } else {
            this.request.headers.push({
              key: "Content-Type",
              value: "application/json",
            });
          }
          break;
        default:
          if (index !== -1) {
            this.request.headers.splice(index, 1);
          }
      }
    },
    prepareHeaders() {
      const headers = new Object();
      if (Array.isArray(this.request.headers)) {
        for (const header of this.request.headers) {
          if (header.key) {
            headers[header.key] = header.value;
          }
        }
      }
      return headers;
    },
    assembleAuthHeader(type, credentials = {}) {
      switch (type) {
        case "basic":
          return (
            "Basic " + btoa(credentials.username + ":" + credentials.password)
          );
        case "bearer":
          return "Bearer " + credentials.token;
      }
    },
    prepareAuth() {
      let header = { key: "Authorization", value: "" };
      switch (this.request.authorization.type) {
        case "saved": {
          const key = this.request.authorization.credentials;
          const credentials = this.credentials[key];
          // console.log("Credentials", credentials);
          header.value = this.assembleAuthHeader(credentials.type, {
            username: credentials.username,
            password: credentials.password,
          });
          break;
        }
        case "basic": {
          header.value = this.assembleAuthHeader(
            this.request.authorization.type,
            {
              username: this.request.authorization.username,
              password: this.request.authorization.password,
            }
          );
          break;
        }
        case "bearer": {
          header.value = this.assembleAuthHeader(
            this.request.authorization.type,
            {
              token: this.request.authorization.token,
            }
          );
          break;
        }
        case "key": {
          header.key = this.request.authorization.api_header;
          header.value = this.request.authorization.api_key;
          break;
        }
      }
      return header;
    },
    clearResponse() {
      this.response = {
        headers: [],
        status: null,
        time: null,
        body: null,
        size: null,
      };
      this.showResponse = false;
    },
    send() {
      // Only send if endpoint is selected
      if (this.request.endpoint) {
        this.sending = true;
        const api = this.apis.find(
          (api) => api.endpoint === this.request.endpoint
        );
        // console.log("API", api);
        // Clear the response window
        this.clearResponse();
        // Prepare request auth (if needed) and add headers
        const auth = this.prepareAuth();
        const headers = this.prepareHeaders();
        if (auth.value !== null) {
          headers[auth.key] = auth.value;
        }
        // console.log("Headers", headers);
        // Prepare request url
        let url =
          this.apiUrl +
          (api.type === "graphql" ? "graphql/" : "rest/") +
          this.project.path +
          "/" +
          this.request.endpoint;
        // console.log("URL", url);
        const params = new URLSearchParams();
        const options = {
          method: this.request.method,
          headers: headers,
        };
        // Handle body of request
        if (
          this.request.body.type === "json" ||
          this.request.body.type === "text"
        ) {
          options.body = this.request.body.text;
        } else if (this.request.body.type === "form-data") {
          const form = new FormData();
          for (const data of this.request.body.formdata) {
            form.append(data.key, data.value);
          }
          options.body = form;
        } else if (this.request.body.type === "graphql") {
          if (this.request.method === "post") {
            options.body = {
              query: this.request.body.query,
            };
            if (this.request.body.variables.length > 0) {
              options.body.variables = this.request.body.variables;
            }
            options.body = JSON.stringify(options.body);
          } else {
            params.append("query", this.request.body.query);
            if (this.request.body.variables.length > 0) {
              params.append("variables", this.request.body.variables);
            }
          }
        }
        // Append query params
        if (this.params.length > 0) {
          for (const param of this.params) {
            params.append(param.key, param.value);
          }
        }
        if (params.toString().length > 0) {
          url += "?" + params.toString();
        }
        // Begin tracking request performance
        window.performance.mark("fetch-start");
        // Send request
        fetch(url, options)
          .then(async (resp) => {
            // Stop tracking request performance and calculate duration
            window.performance.mark("fetch-end");
            window.performance.measure("fetch", "fetch-start", "fetch-end");
            const perf = window.performance.getEntriesByName(
              "fetch",
              "measure"
            );
            // console.log("Performance", perf);
            this.response.time = Math.round(perf[0].duration);
            window.performance.clearMarks();
            window.performance.clearMeasures();
            // Parse response
            let body = await resp.text();
            // console.log("Success:", resp, body);
            // Attempt to format body if json
            try {
              body = JSON.parse(body);
              this.response.body = JSON.stringify(body, null, 2);
            } catch (e) {
              this.response.body = body;
            }
            this.response.status = resp.status;
            resp.headers.forEach((value, key) => {
              this.response.headers.push({ key: key, value: value });
            });
            this.response.size = resp.headers.get("Content-Length");
            this.response.size = !this.response.size ? 0 : this.response.size;
            this.showResponse = true;
            this.sending = false;
          })
          .catch((error) => {
            window.performance.mark("fetch-end");
            window.performance.measure("fetch", "fetch-start", "fetch-end");
            const perf = window.performance.getEntriesByName(
              "fetch",
              "measure"
            );
            // console.log("Performance", perf);
            this.response.time = Math.round(perf[0].duration);
            window.performance.clearMarks();
            window.performance.clearMeasures();
            console.error("Error:", error);
            this.response.body = "Error: " + error.message;
            this.sending = false;
          });
      }
    },
  },
  mounted() {
    if (window.environment === "sandbox") {
      this.request.headers.push({ key: "Environment", value: "sandbox" });
    }
  },
};
</script>

<style lang="scss" scoped>
.card-wrapper {
  max-width: 1250px;
  margin-left: auto;
  margin-right: auto;
}

.filterBtn {
  width: 120px;
  text-align: left;
}

.filterPicker {
  width: 250px;
}

.filterSearch {
  width: 200px;
}

.no-rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.prism-editor {
  height: 500px;
  border: 1px solid #545968;
  border-radius: 0.25rem;
  background: #363d4f;
  font-family: "Source Code Pro", serif;
}

.method-select {
  min-width: 100px;
}

.endpoint-select {
  min-width: 250px;
}
</style>
