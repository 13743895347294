<template>
  <div id="app" class="wrapper">
    <sidebar v-if="authorized" :sidebar-open="sidebarOpen" />
    <div id="main" class="main">
      <navbar v-if="authorized" v-on:sidebar-toggled="updateSidebarOpen" />
      <main class="content">
        <div class="container-fluid p-0 full-height">
          <router-view v-if="!loading" />
          <loader color="#3F80EA" class="mx-auto my-5" v-if="loading" />
        </div>
      </main>
      <footerbar />
    </div>
  </div>
</template>

<script>
import { auth } from "@/firebase";
//import "@/assets/scss/dark.scss";
import Sidebar from "@/components/Sidebar";
import Navbar from "@/components/Navbar";
import Footerbar from "@/components/Footer";
import Loader from "./components/Loader";

export default {
  name: "App",
  components: {
    Sidebar,
    Navbar,
    Footerbar,
    Loader,
  },
  data() {
    return {
      loading: true,
      authorized: false,
      sidebarOpen: false,
    };
  },
  methods: {
    updateSidebarOpen() {
      const state = this.sidebarOpen ? false : true;
      console.log("updateSidebarOpen", state);
      this.sidebarOpen = state;
    },
  },
  beforeCreate() {
    // Set siderbar open state based on viewport size
    this.sidebarOpen = window.innerWidth >= 975;
    // Get last used theme from localstorage
    const theme = localStorage.getItem("theme");
    if (theme === "light") {
      import("@/assets/scss/light.scss");
      document.body.dataset["theme"] = "light";
    } else {
      import("@/assets/scss/dark.scss");
      document.body.dataset["theme"] = "dark";
    }
  },
  created() {
    const theme = localStorage.getItem("theme");
    if (theme === "light") {
      document.body.dataset["theme"] = "light";
    } else {
      document.body.dataset["theme"] = "dark";
    }
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.authorized = true;
        window.authorized = true;
        window.user = user;
      }
      this.loading = false;
    });
  },
};
</script>

<style lang="scss">
body[data-theme="dark"] {
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #293042 !important;
  }
}

.full-height {
  height: 100%;
}

b,
strong,
.font-weight-bold {
  font-weight: 700 !important;
}

.custom-control {
  z-index: 0 !important;
}
</style>
