<template>
  <div class="wrapper">
    <div class="container-fluid" v-if="loading">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <loader class="my-5" color="rgba(255, 152, 0, 0.75)"></loader>
        </div>
      </div>
    </div>
    <div class="container-fluid" v-if="!loading">
      <div class="row">
        <div class="col">
          <div class="card card-shadow mt-3">
            <div class="container-fluid">
              <div class="row">
                <div class="col text-left">
                  <div class="card-body">
                    <b-form inline>
                      <b-form-input
                        size="sm"
                        v-model="searchText"
                        placeholder="Search..."
                      ></b-form-input>
                    </b-form>
                  </div>
                </div>
                <div class="col text-right">
                  <div class="card-body">
                    <b-dropdown
                      split
                      variant="primary"
                      size="sm"
                      right
                      @click="newArticle('article')"
                    >
                      <template #button-content>
                        <i class="fas fa-plus"></i> Create Article
                      </template>
                      <b-dropdown-item @click="newArticle('category')"
                        >Create Category</b-dropdown-item
                      >
                    </b-dropdown>
                  </div>
                </div>
              </div>
              <b-row>
                <b-col class="mx-3 w-100">
                  <b-alert show variant="secondary">
                    <div
                      class="alert-message d-flex justify-content-left w-100"
                    >
                      <b-link class="text-dark" @click="goHome"
                        ><i class="fas fa-home"></i
                      ></b-link>
                      <span v-for="(breadcrumb, index) in path" :key="index">
                        <i class="fas fa-angle-right mx-2 mt-1"></i>
                        <b-link
                          class="text-dark"
                          @click="goToBreadcrumb(breadcrumb, index)"
                          >{{ breadcrumb.name }}</b-link
                        >
                      </span>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
              <div class="row">
                <div class="col">
                  <b-table
                    hover
                    striped
                    responsive
                    :fields="fields"
                    :items="filteredArticles"
                  >
                    <template #cell(actions)="data">
                      <b-button
                        variant="primary"
                        size="sm"
                        @click="exploreCategory(data.item)"
                        v-if="data.item.type === 'category'"
                        ><i class="far fa-folder-open"></i
                      ></b-button>
                      <b-button
                        variant="primary"
                        size="sm"
                        class="ml-1"
                        @click="edit(data.item)"
                        ><i class="fas fa-edit"></i
                      ></b-button>
                      <b-button
                        variant="danger"
                        size="sm"
                        class="ml-1"
                        v-b-modal="'confirmDelete' + data.item['.key']"
                        ><i class="far fa-trash-alt"></i
                      ></b-button>

                      <b-modal
                        :id="'confirmDelete' + data.item['.key']"
                        title="Delete Article"
                        ok-title="Delete"
                        ok-variant="danger"
                        @ok="deleteArticle(data.item['.key'])"
                      >
                        Are you sure you want to delete article
                        {{ data.item.name }}? This cannot be undone.
                      </b-modal>
                    </template>
                  </b-table>
                </div>
                <div class="col profile-tab" v-if="showEdit">
                  <b-form @submit.prevent="isNew ? create() : update()">
                    <b-tabs content-class="mt-3 mx-3" class="mb-5" fill>
                      <b-tab title="Article" active>
                        <div class="form-wrapper">
                          <b-form-group label="Type" label-for="radio-type">
                            <b-form-radio-group
                              id="radio-type"
                              v-model="article.type"
                              :options="[
                                { value: 'category', text: 'Category' },
                                { value: 'article', text: 'Article' },
                              ]"
                              required
                              :disabled="!isNew"
                            ></b-form-radio-group>
                          </b-form-group>
                          <b-form-group
                            id="input-name"
                            label="Name"
                            label-for="input-name"
                          >
                            <b-form-input
                              id="input-name"
                              v-model="article.name"
                              type="text"
                              required
                              placeholder="e.g. My New Article"
                            ></b-form-input>
                          </b-form-group>
                          <b-form-group
                            id="input-description"
                            label="Description"
                            label-for="input-description"
                          >
                            <b-form-textarea
                              id="text-description"
                              v-model="article.description"
                              placeholder="e.g. This article explains..."
                              rows="4"
                            ></b-form-textarea>
                          </b-form-group>
                          <b-form-group
                            label="Image"
                            label-for="input-image"
                            v-if="article.type === 'category'"
                          >
                            <b-input-group class="mb-3">
                              <b-form-file
                                accept="image/*"
                                v-model="image"
                                class="rounded"
                              ></b-form-file>
                              <b-button
                                variant="primary"
                                class="ml-2 mb-1"
                                :disabled="uploading"
                                @click="uploadImage()"
                              >
                                <b-spinner small v-if="uploading"></b-spinner>
                                <span class="sr-only" v-if="uploading"
                                  >Loading...</span
                                >
                                <i
                                  class="fas fa-cloud-upload-alt mr-1"
                                  v-if="!uploading"
                                ></i>
                                <span v-if="!uploading">Start upload</span>
                              </b-button>
                            </b-input-group>
                            <b-img
                              :src="article.image"
                              fluid
                              alt="Category"
                              class="border rounded"
                            ></b-img>
                          </b-form-group>
                          <b-form-group
                            label-for="chk-published"
                            v-if="article.type === 'article'"
                          >
                            <b-form-checkbox
                              id="chk-published"
                              v-model="article.published"
                              switch
                            >
                              Published
                            </b-form-checkbox>
                          </b-form-group>
                        </div>
                      </b-tab>
                      <template #tabs-end>
                        <li
                          role="presentation"
                          class="
                            nav-item
                            align-self-center
                            justify-content-end
                            text-right
                          "
                        >
                          <b-dropdown
                            split
                            variant="primary"
                            size="sm"
                            right
                            class="mr-1"
                            @click="openEditor"
                            v-if="article.type === 'article'"
                          >
                            <template #button-content>
                              <i class="fas fa-edit"></i> Edit
                            </template>
                            <b-dropdown-item @click="openPreviewer"
                              >Preview</b-dropdown-item
                            >
                          </b-dropdown>
                          <b-button
                            type="submit"
                            variant="success"
                            :disabled="saving"
                            size="sm"
                            class="mr-1"
                          >
                            <span v-if="saving">
                              <b-spinner small></b-spinner>
                              <span class="sr-only">Saving...</span>
                            </span>
                            <span v-else>
                              <i class="far fa-save"></i>
                            </span>
                          </b-button>
                          <b-button
                            variant="danger"
                            @click="closeEdit"
                            size="sm"
                            class="mr-1"
                            ><i class="far fa-window-close"></i
                          ></b-button>
                        </li>
                      </template>
                    </b-tabs>
                  </b-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { storage } from "../../firebase";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import Loader from "@/components/Loader";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "Articles",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    loader: Loader,
  },
  data() {
    return {
      bind: [{ ref: "articles", target: "articles" }],
      loading: false,
      saving: false,
      uploading: false,
      articles: [],
      articleKey: null,
      article: {},
      isNew: false,
      fields: [
        {
          key: ".key",
          label: "Key",
          sortable: false,
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "description",
          label: "Description",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      showEdit: false,
      searchText: "",
      images: {},
      image: null,
      root: ["articles"],
      path: [],
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile", "config"]),
    ...mapGetters(["searchConfig"]),
    filteredArticles() {
      if (this.searchText.length > 0) {
        const searchText = this.searchText.toLowerCase();
        return this.articles.filter((article) => {
          return (
            article[".key"].toLowerCase().includes(searchText) ||
            article.name.toLowerCase().includes(searchText) ||
            article.description.toLowerCase().includes(searchText)
          );
        });
      } else {
        return this.articles;
      }
    },
    categoryList() {
      return this.articles.filter((article) => {
        return article.type === "category";
      });
    },
    articleList() {
      return this.articles.filter((article) => {
        return article.type === "article";
      });
    },
    topLevel() {
      return this.root.length === 1;
    },
    pathString() {
      return this.path
        .map((breadcrumb) => {
          return breadcrumb.slug;
        })
        .join("/");
    },
  },
  watch: {
    // articles: {
    //   immediate: false,
    //   handler(articles) {
    //     articles.forEach((article) => {
    //       if (article.type === "category" && "image" in article) {
    //         this.getImageUrl("articles/" + article.image).then((url) => {
    //           this.images[article[".key"]] = url;
    //         });
    //       }
    //     });
    //   },
    // },
    root: {
      immediate: true,
      handler(root) {
        this.bindObject(root.join("/"), null, "articles");
        this.closeEdit();
      },
    },
    path: {
      immediate: false,
      handler() {
        history.replaceState({}, null, "/kb/" + this.pathString);
      },
    },
  },
  methods: {
    edit(article) {
      this.isNew = false;
      this.articleKey = article[".key"];
      this.article = { ...article };
      this.image = null;
      this.showEdit = true;
    },
    closeEdit() {
      this.article = {};
      this.showEdit = false;
    },
    openEditor() {
      let url =
        window.location.protocol +
        "//" +
        window.location.host +
        "/kb/editor/" +
        this.root.join("/") +
        "/" +
        this.articleKey;
      console.log(url);
      window.open(url);
    },
    openPreviewer() {
      let url =
        window.location.protocol +
        "//" +
        window.location.host +
        "/kb/previewer/" +
        this.root.join("/") +
        "/" +
        this.articleKey;
      window.open(url);
    },
    newArticle(type) {
      this.isNew = true;
      this.article = { type: type };
      this.showEdit = true;
    },
    create() {
      this.saving = true;
      const article = { ...this.article };
      this.createObject(this.root.join("/"), article)
        .then((result) => {
          this.articleKey = result.key;
          this.isNew = false;
          this.saving = false;
          this.notyf.success("Article created successfully.");
        })
        .catch((error) => {
          this.saving = false;
          this.notyf.error("An error occurred creating the article: " + error);
          console.error("Unable to create article", error);
        });
      return true;
    },
    update() {
      this.saving = true;
      const article = { ...this.article };
      if (this.articleKey && this.articleKey.length > 0) {
        this.updateObject(this.root.join("/"), this.articleKey, article)
          .then(() => {
            this.saving = false;
            this.notyf.success("Article updated successfully.");
          })
          .catch((error) => {
            this.saving = false;
            this.notyf.error(
              "An error occurred updating the article: " + error
            );
            console.error("Unable to update article", this.articleKey, error);
          });
      }
      return true;
    },
    deleteArticle(key) {
      this.saving = true;
      this.deleteObject(this.root.join("/"), key)
        .then(() => {
          if (key === this.articleKey) {
            this.closeEdit();
          }
          this.saving = false;
          this.notyf.success("Article deleted successfully.");
        })
        .catch((error) => {
          this.saving = false;
          this.notyf.error("An error occurred deleting the article: " + error);
          console.error("Unable to delete article", key, error);
        });
      return key;
    },
    uploadImage() {
      console.log("Image", this.image);
      const reader = new FileReader();
      const file = this.image;

      // Start button loading spinner
      this.uploading = true;

      if (!file) {
        // Stop button loading spinner
        this.uploading = false;
      } else {
        const extension = file.name.split(".").pop();
        const filename = this.refkey + "-" + uuidv4() + "." + extension;
        console.log("File: " + filename);

        // Get storage reference
        const ref = storage.ref().child("articles/" + filename);

        reader.addEventListener(
          "load",
          () => {
            ref.put(file).then(() => {
              this.article.image = filename;
              // Stop button loading spinner
              this.uploading = false;
            });
          },
          false
        );

        if (file) {
          reader.readAsBinaryString(file);
        }
      }
    },
    goHome() {
      this.root = ["articles"];
      this.path = [];
    },
    goBack() {
      this.root.splice(-2, 2);
      this.path.pop();
    },
    exploreCategory(category) {
      console.log(category);
      this.root.push(category[".key"], "articles");
      this.path.push({
        name: category.name,
        slug: category.slug,
        root: [...this.root],
      });
    },
    goToBreadcrumb(breadcrumb, index) {
      console.log(breadcrumb);
      this.root = breadcrumb.root;
      this.path.splice(index, this.path.length - index);
    },
    async setRootFromPath(routePath) {
      console.log("Route Path: " + routePath);
      const segments = routePath.split("/");
      let root = ["articles"];
      let path = [];
      for (const segment of segments) {
        const snapshot = await this.getObjectSnapshot(
          root.join("/"),
          null,
          true
        );
        snapshot.forEach(function (childSnapshot) {
          const key = childSnapshot.key;
          const category = childSnapshot.val();
          if (
            category.type === "category" &&
            category.slug === segment.toLowerCase()
          ) {
            root.push(key, "articles");
            path.push({
              name: category.name,
              slug: category.slug,
              root: [...root],
            });
          }
        });
      }
      this.root = [...root];
      this.path = [...path];
    },
  },
  created() {
    if (this.$route.params.path !== undefined) {
      this.setRootFromPath(this.$route.params.path);
    }
  },
};
</script>

<style scoped>
.wrapper {
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
}

.card-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -4px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.background-none {
  background: none;
}

.profile-tab {
  max-width: 600px;
}
</style>
