import algoliasearch from "algoliasearch";

export default class Algolia {
  #client;
  #index;

  constructor(appId, searchKey, index = null) {
    // Initialize Algolia client
    this.initClient(appId, searchKey);
    // Set search index
    if (index !== null) {
      this.setIndex(index);
    }
  }

  // Initialize Algolia search client
  initClient(appId, searchKey) {
    this.#client = algoliasearch(appId, searchKey);
    return this.#client;
  }

  // Set search index
  setIndex(index) {
    this.#index = this.#client.initIndex(index);
  }

  // Retrieve objects from Algolia index
  async getObjects(ids) {
    return await this.#index.getObjects(ids);
  }

  // Retrieve object from Algolia index
  async getObject(id) {
    return await this.#index.getObject(id);
  }

  // Add/update objects in Algolia index
  async saveObjects(objects) {
    return await this.#index.saveObjects(objects);
  }

  // Add/update an object in Algolia index
  async saveObject(object) {
    return await this.#index.saveObject(object);
  }
}
