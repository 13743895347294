import axios from "axios";
import { auth } from "@/firebase";

export default class Projects {
  remoteUrl = "https://us-central1-api-faker.cloudfunctions.net/";
  //remoteUrl = "http://localhost:5001/api-faker/us-central1/";

  // Fetch an id token from Firebase for API authentication
  async getToken() {
    const user = auth.currentUser;
    const response = await user.getIdToken(false);
    return response;
  }

  // Create project using cloud function
  async create(project) {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "post",
      url: this.remoteUrl + "projects/new",
      data: { ...project },
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment,
      },
    });
  }

  // Send project invite using cloud function
  async sendInvite(id, email) {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "post",
      url: this.remoteUrl + "projects/" + id + "/sendInvite",
      data: { email: email },
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment,
      },
    });
  }

  // Export project using cloud function
  async export(id) {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "post",
      url: this.remoteUrl + "projects/" + id + "/export",
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment,
      },
    });
  }

  // Import project using cloud function
  async import(data) {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "post",
      url: this.remoteUrl + "projects/import",
      data: data,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Environment: window.environment,
      },
    });
  }
}
