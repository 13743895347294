import { mapState } from "vuex";
import { db, storage } from "../firebase";

export default {
  data() {
    return {
      bind: [],
    };
  },
  watch: {},
  computed: {
    ...mapState(["userProfile"]),
    env() {
      const env = window.environment;
      return env;
    },
  },
  methods: {
    getObjectPath(ref, key = null) {
      let path = ref;
      if (key !== null) {
        path += "/" + key;
      }
      console.log("Path: " + path);
      return path;
    },
    createObjectKey(ref) {
      return db
        .ref(this.getObjectPath(ref))
        .push()
        .then((result) => {
          return result.key;
        });
    },
    createObject(ref, creates) {
      return db.ref(this.getObjectPath(ref, null)).push(creates);
    },
    updateObject(ref, key, updates, overwrite = false) {
      if (overwrite) {
        return db.ref(this.getObjectPath(ref, key)).set(updates);
      } else {
        return db.ref(this.getObjectPath(ref, key)).update(updates);
      }
    },
    deleteObject(ref, key) {
      return db.ref(this.getObjectPath(ref, key)).remove();
    },
    bindObject(ref, key = null, target = null, limit = 500, fromEnd = false) {
      console.log("Bind: " + ref);
      let dbRef;
      if (key !== null) {
        if (fromEnd) {
          dbRef = db.ref(this.getObjectPath(ref, key)).limitToLast(limit);
        } else {
          dbRef = db.ref(this.getObjectPath(ref, key)).limitToFirst(limit);
        }
      } else {
        if (fromEnd) {
          dbRef = db.ref(this.getObjectPath(ref, null)).limitToLast(limit);
        } else {
          dbRef = db.ref(this.getObjectPath(ref, null)).limitToFirst(limit);
        }
      }
      if (target) {
        return this.$rtdbBind(target, dbRef);
      } else {
        return this.$rtdbBind(ref, dbRef);
      }
    },
    getObjectSnapshot(ref, key = null, returnSnapshot = false) {
      return db
        .ref(this.getObjectPath(ref, key))
        .once("value")
        .then(function (snapshot) {
          return returnSnapshot ? snapshot : snapshot.val();
        });
    },
    getImageUrl(image) {
      return storage.ref(image).getDownloadURL();
    },
    getIdFromSlug(slug) {
      return db
        .ref(this.getObjectPath("slugs"))
        .once("value")
        .then((snapshot) => {
          const slugs = snapshot.val();
          return slugs[slug];
        });
    },
  },
  created() {
    this.bind.forEach((binding) => {
      if (binding instanceof Object) {
        this.bindObject(binding.ref, binding.key, binding.target).catch(
          (error) => {
            if (String(error).includes("permission_denied")) {
              console.log(binding.ref + ": Permission denied");
            } else {
              console.log(error);
            }
          }
        );
      } else {
        const ref = binding;
        this.bindObject(ref).catch((error) => {
          if (String(error).includes("permission_denied")) {
            console.log(ref + ": Permission denied");
          } else {
            console.log(error);
          }
        });
      }
    });
  },
};
