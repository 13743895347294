<template>
  <div class="wrapper">
    <div class="container-fluid" v-if="loading">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <loader class="my-5" color="rgba(255, 152, 0, 0.75)"></loader>
        </div>
      </div>
    </div>
    <div class="container-fluid" v-if="!loading">
      <div class="row">
        <div class="col">
          <div class="card card-shadow settings-card mt-3">
            <div class="container-fluid">
              <div class="row">
                <div class="col mt-2">
                  <b-tabs pills>
                    <b-tab title="Inboxes">
                      <div class="card-body container">
                        <div class="row settings-row">
                          <div class="col">
                            <h5>Manage Inboxes</h5>
                            <small class="text-muted"
                              >Add, rename, remove, or re-arrange shared
                              inboxes. All tickets must be moved out of an inbox
                              before it can be removed.</small
                            >
                            <b-input-group prepend="Default" class="my-3">
                              <b-form-select
                                :options="inboxes"
                                text-field="name"
                                value-field=".key"
                                v-model="selected_default"
                              />
                              <b-input-group-append>
                                <b-button
                                  variant="success"
                                  class="rounded-right"
                                  title="Save"
                                  @click="saveDefault"
                                >
                                  <i class="far fa-save"></i>
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                            <table
                              id="tblSettingsInboxes"
                              class="table table-striped mt-3"
                            >
                              <thead>
                                <tr>
                                  <th></th>
                                  <th>Name</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <draggable
                                tag="tbody"
                                handle=".handle"
                                :list="inboxes"
                                @end="updateOrder"
                              >
                                <tr
                                  class="settings-inbox-row"
                                  v-for="(inbox, index) in inboxes"
                                  :key="index"
                                >
                                  <td class="handle list-handle">
                                    <i class="fas fa-grip-vertical"></i>
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="e.g. Sales"
                                      v-model="inbox.name"
                                    />
                                  </td>
                                  <td>
                                    <button
                                      class="btn btn-success mr-1"
                                      title="Save"
                                      @click="saveInbox(inbox)"
                                    >
                                      <i class="far fa-save"></i>
                                    </button>
                                    <button
                                      class="btn btn-danger"
                                      title="Delete"
                                      v-b-modal="'confirmInboxDelete' + index"
                                    >
                                      <i class="far fa-trash-alt"></i>
                                    </button>

                                    <b-modal
                                      :id="'confirmInboxDelete' + index"
                                      title="Delete Inbox"
                                      ok-title="Delete"
                                      ok-variant="danger"
                                      @ok="deleteInbox(inbox)"
                                    >
                                      Are you sure you want to delete shared
                                      inbox {{ inbox.name }}? This cannot be
                                      undone.
                                    </b-modal>
                                  </td>
                                </tr>
                              </draggable>
                            </table>
                            <button
                              class="btn btn-primary btn-sm mt-0 mb-4"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Add Shared Inbox"
                              @click="addInbox"
                            >
                              <i class="fas fa-plus"></i> Add Shared Inbox
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="Rules">
                      <div class="card-body container">
                        <div class="row settings-row">
                          <div class="col">
                            <h5>Manage Automation Rules</h5>
                            <small class="text-muted"
                              >Use rules to automate actions on tickets.</small
                            >
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col">
                            <div id="settings-rule-list" class="list-group">
                              <div
                                class="list-group-item mb-3 border rounded"
                                v-for="(rule, index) in rules"
                                :key="index"
                              >
                                <div
                                  class="
                                    d-flex
                                    w-100
                                    justify-content-between
                                    mb-2
                                  "
                                >
                                  <form class="form-inline">
                                    <div class="form-group">
                                      <label class="mr-1">Name:</label>
                                      <input
                                        name="name"
                                        type="text"
                                        class="form-control"
                                        placeholder="e.g. Move to Sales"
                                        v-model="rule.name"
                                      />
                                    </div>
                                  </form>
                                  <span>
                                    <button
                                      class="btn btn-success mr-1"
                                      title="Save"
                                      @click="saveRule(rule)"
                                    >
                                      <i class="far fa-save"></i>
                                    </button>
                                    <button
                                      class="btn btn-danger"
                                      title="Delete"
                                      v-b-modal="'confirmRuleDelete' + index"
                                    >
                                      <i class="far fa-trash-alt"></i>
                                    </button>

                                    <b-modal
                                      :id="'confirmRuleDelete' + index"
                                      title="Delete Rule"
                                      ok-title="Delete"
                                      ok-variant="danger"
                                      @ok="deleteRule(rule)"
                                    >
                                      Are you sure you want to delete rule
                                      {{ rule.name }}? This cannot be undone.
                                    </b-modal>
                                  </span>
                                </div>
                                <div
                                  class="
                                    d-flex
                                    w-100
                                    justify-content-between
                                    mt-3
                                  "
                                >
                                  <div class="flex-fill pr-3">
                                    <div class="w-100 text-center">
                                      <h6>Conditions</h6>
                                    </div>
                                    <table class="table table-striped">
                                      <thead>
                                        <tr>
                                          <th>Field</th>
                                          <th></th>
                                          <th>Value</th>
                                          <th></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          v-for="(
                                            condition, j
                                          ) in rule.conditions"
                                          :key="j"
                                        >
                                          <td>
                                            <b-form-select
                                              :options="conditionOptions"
                                              v-model="condition.field"
                                            ></b-form-select>
                                          </td>
                                          <td>
                                            <b-form-select
                                              :options="['=', '!=']"
                                              v-model="condition.operator"
                                            ></b-form-select>
                                          </td>
                                          <td>
                                            <b-form-input
                                              type="text"
                                              placeholder="e.g. 123"
                                              v-model="condition.value"
                                              v-if="condition.field !== 'event'"
                                            />
                                            <b-form-select
                                              :options="[
                                                {
                                                  text: 'Ticket Created',
                                                  value: 'ticket-created',
                                                },
                                                {
                                                  text: 'Ticket Updated',
                                                  value: 'ticket-updated',
                                                },
                                              ]"
                                              v-model="condition.value"
                                              v-if="condition.field === 'event'"
                                            ></b-form-select>
                                          </td>
                                          <td>
                                            <button
                                              class="btn btn-danger"
                                              title="Delete"
                                              @click="deleteCondition(index, j)"
                                            >
                                              <i class="far fa-trash-alt"></i>
                                            </button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <button
                                      class="btn btn-primary btn-sm mt-0 mb-2"
                                      data-toggle="tooltip"
                                      data-placement="bottom"
                                      title="Add Condition"
                                      @click="addCondition(index)"
                                    >
                                      <i class="fas fa-plus"></i> Add Condition
                                    </button>
                                  </div>
                                  <div class="flex-fill pl-3">
                                    <div class="w-100 text-center">
                                      <h6>Actions</h6>
                                    </div>
                                    <table class="table table-striped">
                                      <thead>
                                        <tr>
                                          <th>Action</th>
                                          <th>Parameters</th>
                                          <th></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          v-for="(action, j) in rule.actions"
                                          :key="j"
                                        >
                                          <td>
                                            <b-form-select
                                              :options="actionOptions"
                                              v-model="action.type"
                                            ></b-form-select>
                                          </td>
                                          <td>
                                            <b-form-select
                                              :options="inboxes"
                                              text-field="name"
                                              value-field=".key"
                                              v-model="action.field"
                                              v-if="action.type === 'move'"
                                            >
                                            </b-form-select>
                                            <b-form-select
                                              :options="[
                                                {
                                                  value: 'ticket-status',
                                                  text: 'Ticket Status',
                                                },
                                              ]"
                                              v-model="action.field"
                                              v-if="action.type === 'update'"
                                            ></b-form-select>
                                            <b-form-input
                                              type="text"
                                              placeholder="e.g. 123"
                                              v-model="action.value"
                                              v-if="action.type === 'update'"
                                            />
                                            <b-form-select
                                              :options="replies"
                                              text-field="name"
                                              value-field=".key"
                                              v-model="action.field"
                                              v-if="action.type === 'reply'"
                                            ></b-form-select>
                                            <b-form-input
                                              type="text"
                                              placeholder="e.g. john.smith@apifaker.dev"
                                              v-model="action.value"
                                              v-if="action.type === 'notify'"
                                            />
                                          </td>
                                          <td>
                                            <button
                                              class="btn btn-danger"
                                              title="Delete"
                                              @click="deleteAction(index, j)"
                                            >
                                              <i class="far fa-trash-alt"></i>
                                            </button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <button
                                      class="btn btn-primary btn-sm mt-0 mb-2"
                                      data-toggle="tooltip"
                                      data-placement="bottom"
                                      title="Add Action"
                                      @click="addAction(index)"
                                    >
                                      <i class="fas fa-plus"></i> Add Action
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <button
                              id="btnAddRule"
                              class="btn btn-primary btn-sm mb-4"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Add Rule"
                              @click="addRule"
                            >
                              <i class="fas fa-plus"></i> Add Rule
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="SLAs">
                      <div class="card-body container">
                        <div class="row settings-row">
                          <div class="col">
                            <h5>Manage Service Level Agreements</h5>
                            <small class="text-muted"
                              >Configure SLAs for ticket responses.</small
                            >
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <table
                              id="tblSettingsSLAs"
                              class="table table-striped mt-3"
                            >
                              <thead>
                                <tr>
                                  <th>Role</th>
                                  <th>Name</th>
                                  <th>Type</th>
                                  <th>Duration (min)</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody id="settings-sla-list">
                                <tr
                                  class="settings-sla-row"
                                  v-for="(sla, index) in slas"
                                  :key="index"
                                >
                                  <td>
                                    <b-form-select
                                      :options="slaRoleOptions"
                                      v-model="sla.role"
                                    ></b-form-select>
                                  </td>
                                  <td>
                                    <input
                                      name="name"
                                      type="text"
                                      class="form-control"
                                      placeholder="e.g. Pro First Response"
                                      v-model="sla.name"
                                    />
                                  </td>
                                  <td>
                                    <b-form-select
                                      :options="slaTypeOptions"
                                      v-model="sla.type"
                                    ></b-form-select>
                                  </td>
                                  <td>
                                    <b-form-input
                                      type="number"
                                      placeholder="e.g. 10"
                                      v-model="sla.duration"
                                    />
                                  </td>
                                  <td>
                                    <button
                                      class="btn btn-success mr-1"
                                      title="Save"
                                      @click="saveSLA(sla)"
                                    >
                                      <i class="far fa-save"></i>
                                    </button>
                                    <button
                                      class="btn btn-danger"
                                      title="Delete"
                                      v-b-modal="'confirmSLADelete' + index"
                                    >
                                      <i class="far fa-trash-alt"></i>
                                    </button>

                                    <b-modal
                                      :id="'confirmSLADelete' + index"
                                      title="Delete SLA"
                                      ok-title="Delete"
                                      ok-variant="danger"
                                      @ok="deleteSLA(sla)"
                                    >
                                      Are you sure you want to delete sla
                                      {{ sla.name }}? This cannot be undone.
                                    </b-modal>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <button
                              class="btn btn-primary btn-sm mt-0 mb-4"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Add SLA"
                              @click="addSLA"
                            >
                              <i class="fas fa-plus"></i> Add SLA
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="Canned Replies">
                      <div class="card-body container">
                        <div class="row settings-row">
                          <div class="col">
                            <h5>Manage Canned Replies</h5>
                            <small class="text-muted"
                              >Use canned replies for frequent ticket
                              responses.</small
                            >
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <b-button
                              variant="primary"
                              size="sm"
                              class="mt-0 mb-4"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Add Reply"
                              @click="addReply"
                            >
                              <i class="fas fa-plus"></i> Add Reply
                            </b-button>

                            <b-list-group>
                              <b-list-group-item
                                class="flex-column align-items-start"
                                v-for="(reply, index) in replies"
                                :key="index"
                              >
                                <div
                                  class="d-flex w-100 justify-content-between"
                                >
                                  <span>{{ reply.name }}</span>
                                  <span>
                                    <b-button
                                      variant="primary"
                                      size="sm"
                                      class="mr-1"
                                      @click="edit(reply)"
                                      ><i class="fas fa-edit"></i
                                    ></b-button>
                                    <b-button
                                      variant="danger"
                                      size="sm"
                                      title="Delete"
                                      v-b-modal="'confirmReplyDelete' + index"
                                    >
                                      <i class="far fa-trash-alt"></i>
                                    </b-button>

                                    <b-modal
                                      :id="'confirmReplyDelete' + index"
                                      title="Delete Reply"
                                      ok-title="Delete"
                                      ok-variant="danger"
                                      @ok="deleteReply(reply)"
                                    >
                                      Are you sure you want to delete canned
                                      reply {{ reply.name }}? This cannot be
                                      undone.
                                    </b-modal>
                                  </span>
                                </div>
                              </b-list-group-item>
                            </b-list-group>
                          </div>
                          <div class="col">
                            <b-input-group prepend="Name" class="mb-3">
                              <b-form-input v-model="reply.name" />
                              <b-input-group-append>
                                <b-button
                                  variant="success"
                                  class="rounded-right"
                                  title="Save"
                                  @click="saveReply"
                                >
                                  <i class="far fa-save"></i>
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                            <text-editor
                              v-model="reply.text"
                              placeholder="Thank for your contacting us..."
                              preset="basic"
                            ></text-editor>
                          </div>
                        </div>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import Loader from "@/components/Loader";
import draggable from "vuedraggable";
import TextEditor from "@/components/TextEditor";

export default {
  name: "HelpdeskSettings",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    Loader,
    draggable,
    TextEditor,
  },
  data() {
    return {
      bind: [
        { ref: "helpdesk/meta/default_mailbox", target: "default_mailbox" },
        { ref: "helpdesk/meta/rules", target: "rules" },
        { ref: "helpdesk/meta/slas", target: "slas" },
        { ref: "helpdesk/meta/replies", target: "replies" },
      ],
      loading: false,
      saving: false,
      default_mailbox: {},
      selected_default: "",
      inboxes: [],
      rules: [],
      slas: [],
      replies: [],
      conditionOptions: [
        { value: "event", text: "Event" },
        { value: "shared-inbox", text: "Shared Inbox" },
        { value: "receiver-email", text: "Receiver Email" },
        { value: "sender-email", text: "Sender Email" },
        { value: "sender-user", text: "Sender User" },
        { value: "ticket-status", text: "Ticket Status" },
        { value: "assigned-user", text: "Assigned User" },
      ],
      actionOptions: [
        { value: "move", text: "Move" },
        { value: "update", text: "Update" },
        { value: "reply", text: "Reply" },
        { value: "archive", text: "Archive" },
        { value: "spam", text: "Mark as Spam" },
        { value: "notify", text: "Notify" },
      ],
      slaRoleOptions: ["Free Member", "Paid Member"],
      slaTypeOptions: [
        { value: "first-response", text: "First Response" },
        { value: "follow-up", text: "Follow-Up" },
        { value: "completion", text: "Completion" },
      ],
      replyKey: null,
      reply: {
        name: "",
        text: "",
      },
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
  },
  watch: {
    default_mailbox: {
      immediate: false,
      handler(mailbox) {
        if (mailbox) {
          this.selected_default = mailbox.key;
        }
      },
    },
  },
  methods: {
    saveDefault() {
      const default_mailbox = {
        key: this.selected_default,
        ...this.inboxes[this.getIndex(this.inboxes, this.selected_default)],
      };
      return this.updateObject(
        "helpdesk/meta/default_mailbox",
        null,
        default_mailbox
      )
        .then(() => {
          this.notyf.success("Default mailbox saved successfully");
        })
        .catch((error) => {
          console.error(error);
          this.notyf.error(
            "An error occurred while updating the default mailbox. Please try again later."
          );
        });
    },
    updateOrder() {
      let inboxes = [...this.inboxes];
      inboxes.forEach((inbox, index) => {
        inboxes[index].order = index;
      });
      return this.updateObject("helpdesk/mailboxes", null, inboxes).catch(
        (error) => {
          console.error(error);
          this.notyf.error(
            "An error occurred while updating the inboxes. Please try again later."
          );
        }
      );
    },
    addInbox() {
      const inbox = {
        name: "",
        slug: "",
      };
      this.createObject("helpdesk/mailboxes", inbox).catch((error) => {
        console.log(error);
        this.notyf.error(
          "An error occurred while creating the inbox. Please try again later."
        );
      });
    },
    saveInbox(inbox) {
      if (!("slug" in inbox) || inbox.slug === "") {
        inbox.slug = this.getUniqueSlug(this.inboxes, inbox.name);
      }
      return this.updateObject("helpdesk/mailboxes", inbox[".key"], inbox)
        .then(() => {
          this.notyf.success("Inbox saved successfully");
        })
        .catch((error) => {
          console.error(error);
          this.notyf.error(
            "An error occurred while saving the inbox. Please try again later."
          );
        });
    },
    deleteInbox(inbox) {
      console.log(inbox);
      this.deleteObject("helpdesk/mailboxes", inbox[".key"])
        .then(() => {
          // Remove tickets in inbox
          this.deleteObject("helpdesk/tickets/" + inbox[".key"]).catch(
            (error) => {
              console.log(error);
              this.notyf.error(
                "An error occurred while deleting the inbox. Please try again later."
              );
            }
          );
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(
            "An error occurred while deleting the inbox. Please try again later."
          );
        });
    },
    addRule() {
      const rule = {
        name: "",
        conditions: [],
        actions: [],
      };
      this.createObject("helpdesk/meta/rules", rule).catch((error) => {
        console.log(error);
        this.notyf.error(
          "An error occurred while creating the rule. Please try again later."
        );
      });
    },
    saveRule(rule) {
      console.log("Rule", rule[".key"], rule);
      return this.updateObject("helpdesk/meta/rules", rule[".key"], rule)
        .then(() => {
          this.notyf.success("Rule saved successfully");
        })
        .catch((error) => {
          console.error(error);
          this.notyf.error(
            "An error occurred while saving the rule. Please try again later."
          );
        });
    },
    deleteRule(rule) {
      console.log(rule);
      this.deleteObject("helpdesk/meta/rules", rule[".key"])
        .then(() => {
          this.notyf.success("Rule deleted successfully");
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(
            "An error occurred while deleting the rule. Please try again later."
          );
        });
    },
    addCondition(index) {
      let rule = { ...this.rules[index] };
      if (!Array.isArray(rule.conditions)) {
        rule.conditions = [];
      }
      rule.conditions.push({});
      this.$set(this.rules[index], "conditions", rule.conditions);
    },
    deleteCondition(ruleIndex, condIndex) {
      let rule = { ...this.rules[ruleIndex] };
      rule.conditions.splice(condIndex, 1);
      this.$set(this.rules[ruleIndex], "conditions", rule.conditions);
    },
    addAction(index) {
      let rule = { ...this.rules[index] };
      if (!Array.isArray(rule.actions)) {
        rule.actions = [];
      }
      rule.actions.push({});
      this.$set(this.rules[index], "actions", rule.actions);
    },
    deleteAction(ruleIndex, actionIndex) {
      let rule = { ...this.rules[ruleIndex] };
      rule.actions.splice(actionIndex, 1);
      this.$set(this.rules[ruleIndex], "actions", rule.actions);
    },
    addSLA() {
      const sla = {
        name: "",
      };
      this.createObject("helpdesk/meta/slas", sla).catch((error) => {
        console.log(error);
        this.notyf.error(
          "An error occurred while creating the SLA. Please try again later."
        );
      });
    },
    saveSLA(sla) {
      sla.duration = Number(sla.duration);
      return this.updateObject("helpdesk/meta/slas", sla[".key"], sla)
        .then(() => {
          this.notyf.success("SLA saved successfully");
        })
        .catch((error) => {
          console.error(error);
          this.notyf.error(
            "An error occurred while saving the SLA. Please try again later."
          );
        });
    },
    deleteSLA(sla) {
      console.log(sla);
      this.deleteObject("helpdesk/meta/slas", sla[".key"])
        .then(() => {
          this.notyf.success("SLA deleted successfully");
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(
            "An error occurred while deleting the SLA. Please try again later."
          );
        });
    },
    edit(reply) {
      this.replyKey = reply[".key"];
      this.reply = { ...reply };
    },
    addReply() {
      const reply = {
        name: "",
        text: "",
      };
      this.createObject("helpdesk/meta/replies", reply)
        .then((resp) => {
          this.replyKey = resp.key;
          this.reply = { ...reply };
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(
            "An error occurred while creating the reply. Please try again later."
          );
        });
    },
    saveReply() {
      const reply = { ...this.reply };
      return this.updateObject("helpdesk/meta/replies", this.replyKey, reply)
        .then(() => {
          this.notyf.success("Reply saved successfully");
        })
        .catch((error) => {
          console.error(error);
          this.notyf.error(
            "An error occurred while saving the reply. Please try again later."
          );
        });
    },
    deleteReply(reply) {
      console.log(reply);
      this.deleteObject("helpdesk/meta/replies", reply[".key"])
        .then(() => {
          this.notyf.success("Reply deleted successfully");
          if (reply[".key"] === this.replyKey) {
            this.replyKey = "";
            this.reply = { name: "", text: " " };
          }
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(
            "An error occurred while deleting the reply. Please try again later."
          );
        });
    },
  },
  created() {
    this.bindObject("helpdesk/mailboxes", null, "inboxes").then(() => {
      // Sort inboxes
      this.inboxes.sort((a, b) => {
        a.order = "order" in a && !isNaN(a.order) ? a.order : 0;
        b.order = "order" in b && !isNaN(b.order) ? b.order : 0;
        return a.order - b.order;
      });
    });
  },
};
</script>

<style scoped>
.wrapper {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.settings-card {
  min-height: 500px;
}

.card-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -4px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.settings-row {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
}

.settings-form-group {
  max-width: 450px;
  margin-right: 0;
  margin-bottom: 0;
}

.caret-button {
  padding: 0.15rem 0.5rem;
}

.list-handle {
  width: 30px;
  height: 28px;
  float: left;
  cursor: pointer;
}
</style>
