import axios from "axios";
import { auth } from "@/firebase";

export default class Messages {
  remoteUrl = "https://us-central1-api-faker.cloudfunctions.net/";
  //remoteUrl = "http://localhost:5001/api-faker/us-central1/";

  // Fetch an id token from Firebase for API authentication
  async getToken() {
    const user = auth.currentUser;
    const response = await user.getIdToken(false);
    return response;
  }

  // Send project invite using cloud function
  async performAction(id, name, value) {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "post",
      url: this.remoteUrl + "messages/" + id + "/performAction",
      data: { name: name, value: value },
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment,
      },
    });
  }
}
