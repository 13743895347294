<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row text-muted">
        <div class="col-6 text-left">
          <ul class="list-inline"></ul>
        </div>
        <div class="col-6 text-right">
          <p class="mb-0">
            &copy; {{ year }} -
            <a href="https://apifaker.dev" class="text-muted">API Faker</a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { DateTime } from "luxon";

export default {
  name: "Footer",
  computed: {
    year() {
      const dt = DateTime.now();
      return dt.toFormat("yyyy");
    },
  },
};
</script>
