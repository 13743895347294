<template>
  <div>
    <div class="container-fluid" v-if="searchClient === false">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <loader class="my-5" color="rgba(255, 152, 0, 0.75)"></loader>
        </div>
      </div>
    </div>
    <div class="container-fluid" v-if="searchClient !== false">
      <ais-instant-search
        :search-client="searchClient"
        :index-name="searchIndex"
      >
        <!-- <div class="row">
          <div class="col">
            <div class="card-deck">
              <div class="card card-shadow">
                <div class="card-body">
                  <h1 class="card-title">
                    {{ users.length }}
                  </h1>
                </div>
                <div class="card-footer bg-dark text-light">Total Members</div>
              </div>
              <div class="card card-shadow">
                <div class="card-body">
                  <h1 class="card-title">
                    {{ freeUsers }}
                  </h1>
                </div>
                <div class="card-footer bg-dark text-light">Free Members</div>
              </div>
              <div class="card card-shadow">
                <div class="card-body">
                  <h1 class="card-title">
                    {{ subscribers }}
                  </h1>
                </div>
                <div class="card-footer bg-dark text-light">
                  Premium Members
                </div>
              </div>
              <div class="card card-shadow">
                <div class="card-body">
                  <h1 class="card-title">
                    {{ admins }}
                  </h1>
                </div>
                <div class="card-footer bg-dark text-light">Admins</div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="row">
          <div class="col">
            <div class="card card-shadow mt-3">
              <div class="container-fluid">
                <div class="row">
                  <div class="col text-left">
                    <div class="card-body">
                      <b-form inline>
                        <ais-refinement-list
                          attribute="plan"
                          searchable
                          show-more
                        >
                          <b-dropdown
                            text="Filter"
                            variant="outline-secondary"
                            slot-scope="{
                              items,
                              isShowingMore,
                              isFromSearch,
                              canToggleShowMore,
                              refine,
                              toggleShowMore,
                              searchForItems,
                            }"
                            size="sm"
                          >
                            <b-dropdown-form>
                              <input
                                @input="
                                  searchForItems($event.currentTarget.value)
                                "
                                placeholder="Search for plan..."
                                class="form-control"
                              />
                            </b-dropdown-form>
                            <b-dropdown-form>
                              <ul class="list-style-none pl-0">
                                <li v-if="isFromSearch && !items.length">
                                  No results.
                                </li>
                                <li v-for="item in items" :key="item.value">
                                  <b-form-checkbox
                                    :checked="item.isRefined"
                                    @input="refine(item.value)"
                                    :style="{
                                      fontWeight: item.isRefined ? 'bold' : '',
                                    }"
                                  >
                                    <div>
                                      <ais-highlight
                                        attribute="item"
                                        :hit="item"
                                      />
                                      <b-badge class="ml-2">{{
                                        item.count.toLocaleString()
                                      }}</b-badge>
                                    </div>
                                  </b-form-checkbox>
                                </li>
                              </ul>
                            </b-dropdown-form>
                            <b-dropdown-item-button
                              @click.stop="toggleShowMore"
                              :disabled="!canToggleShowMore"
                              class="text-center"
                              >{{ !isShowingMore ? "Show more" : "Show less" }}
                            </b-dropdown-item-button>
                          </b-dropdown>
                        </ais-refinement-list>
                        <ais-search-box>
                          <div
                            slot-scope="{
                              currentRefinement,
                              isSearchStalled,
                              refine,
                            }"
                          >
                            <input
                              type="search"
                              :value="currentRefinement"
                              @input="refine($event.currentTarget.value)"
                              placeholder="Search..."
                              class="form-control form-control-sm ml-1"
                            />
                            <span :hidden="!isSearchStalled">Loading...</span>
                          </div>
                        </ais-search-box>
                      </b-form>
                    </div>
                  </div>
                  <div class="col text-right">
                    <div class="card-body">
                      <b-button variant="primary" size="sm" @click="newUser"
                        ><i class="fas fa-plus"></i> Create User</b-button
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <infinite-hits class="mt-4">
                      <div
                        slot-scope="{
                          items,
                          refinePrevious,
                          refineNext,
                          isFirstPage,
                          isLastPage,
                        }"
                      >
                        <b-table-simple hover striped responsive>
                          <b-thead>
                            <b-th>UID</b-th>
                            <b-th>First Name</b-th>
                            <b-th>Last Name</b-th>
                            <b-th>Email</b-th>
                            <b-th>Plan</b-th>
                            <b-th>Attributes</b-th>
                            <b-th>Providers</b-th>
                            <b-th>Actions</b-th>
                          </b-thead>
                          <b-tbody>
                            <b-tr v-if="!isFirstPage">
                              <b-button @click="refinePrevious">
                                Show previous results
                              </b-button>
                            </b-tr>
                            <b-tr v-for="user in items" :key="user.objectID">
                              <b-td>{{ user.objectID }}</b-td>
                              <b-td>{{ user.firstName }}</b-td>
                              <b-td>{{ user.lastName }}</b-td>
                              <b-td>{{ user.email }}</b-td>
                              <b-td>{{ user.plan }}</b-td>
                              <b-td>
                                <b-badge
                                  variant="warning"
                                  v-if="user.claims.vip"
                                  class="mr-1"
                                  >VIP</b-badge
                                >
                                <b-badge
                                  variant="danger"
                                  v-if="user.claims.admin"
                                  class="mr-1"
                                  >Admin</b-badge
                                >
                                <b-badge
                                  variant="primary"
                                  v-if="user.claims.paid"
                                  class="mr-1"
                                  >Paid</b-badge
                                >
                              </b-td>
                              <b-td>
                                <b-badge
                                  variant="secondary"
                                  v-if="'github.com' in user.providers"
                                  >Github</b-badge
                                >
                                <b-badge
                                  variant="danger"
                                  v-if="'google.com' in user.providers"
                                  >Google</b-badge
                                >
                              </b-td>
                              <b-td>
                                <b-button
                                  variant="primary"
                                  size="sm"
                                  @click="edit(user)"
                                  ><i class="fas fa-edit"></i
                                ></b-button>
                                <b-button
                                  variant="danger"
                                  size="sm"
                                  class="ml-1"
                                  v-b-modal="'confirmDelete' + user.objectID"
                                  ><i class="far fa-trash-alt"></i
                                ></b-button>

                                <b-modal
                                  :id="'confirmDelete' + user.objectID"
                                  title="Delete User"
                                  ok-title="Delete"
                                  ok-variant="danger"
                                  @ok="deleteUser(user.objectID)"
                                >
                                  Are you sure you want to delete user
                                  {{ user.firstName + " " + user.lastName }}?
                                  This cannot be undone.
                                </b-modal>
                              </b-td>
                            </b-tr>
                            <b-tr v-if="!isLastPage">
                              <b-button
                                @click="refineNext"
                                v-if="!isLastPage"
                                class="mx-1"
                              >
                                Show more results
                              </b-button>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </div>
                    </infinite-hits>
                  </div>
                  <div class="col profile-tab" v-if="showEdit">
                    <b-form
                      @submit.prevent="
                        isNew ? $bvModal.show('confirmCreate') : update()
                      "
                    >
                      <b-tabs content-class="mt-3 mx-3" class="mb-5" fill>
                        <b-tab title="Profile" active>
                          <div class="form-wrapper">
                            <b-form-group
                              label="First Name"
                              label-for="input-first-name"
                            >
                              <b-form-input
                                id="input-first-name"
                                v-model="user.firstName"
                                type="text"
                                required
                                placeholder="e.g. John"
                              ></b-form-input>
                            </b-form-group>
                            <b-form-group
                              label="Last Name"
                              label-for="input-last-name"
                            >
                              <b-form-input
                                id="input-last-name"
                                v-model="user.lastName"
                                type="text"
                                required
                                placeholder="e.g. Smith"
                              ></b-form-input>
                            </b-form-group>
                            <b-form-group label="Email" label-for="input-email">
                              <b-form-input
                                id="input-username"
                                v-model="user.email"
                                type="email"
                                required
                                placeholder="e.g. john.smith@qualfon.com"
                              ></b-form-input>
                            </b-form-group>
                            <b-form-group
                              label="Password"
                              label-for="input-password"
                            >
                              <b-form-input
                                id="input-password"
                                v-model="user.password"
                                type="password"
                                placeholder="e.g. abc123"
                              ></b-form-input>
                            </b-form-group>
                            <b-form-group
                              label="Address 1"
                              label-for="input-address-1"
                            >
                              <b-form-input
                                id="input-address-1"
                                v-model="user.address1"
                                type="text"
                                placeholder="e.g. 123 Main St"
                              ></b-form-input>
                            </b-form-group>
                            <b-form-group
                              label="Address 2"
                              label-for="input-address-2"
                            >
                              <b-form-input
                                id="input-address-2"
                                v-model="user.address2"
                                type="text"
                                placeholder="e.g. Apt 1A"
                              ></b-form-input>
                            </b-form-group>
                            <b-form-group label="City" label-for="input-city">
                              <b-form-input
                                id="input-city"
                                v-model="user.city"
                                type="text"
                                placeholder="e.g. New York"
                              ></b-form-input>
                            </b-form-group>
                            <b-form-group label="State" label-for="input-state">
                              <b-form-select
                                id="input-state"
                                :options="states"
                                v-model="user.state"
                                placeholder="e.g. NY"
                              />
                            </b-form-group>
                            <b-form-group label="Zip" label-for="input-zip">
                              <b-form-input
                                id="input-zip"
                                v-model="user.zip"
                                type="text"
                                placeholder="e.g. 12345"
                              ></b-form-input>
                            </b-form-group>
                            <b-form-group
                              label="Stripe customer"
                              label-for="input-stripe"
                            >
                              <b-form-input
                                id="input-stripe"
                                v-model="user.stripe_customer_id"
                                type="text"
                                placeholder=""
                              ></b-form-input>
                            </b-form-group>
                          </div>
                        </b-tab>
                        <b-tab title="Permissions">
                          <div class="form-wrapper">
                            <b-form-group label="User Group" label-for="claims">
                              <b-list-group id="claims">
                                <b-list-group-item>
                                  <b-form-checkbox
                                    v-model="user.claims.admin"
                                    :value="true"
                                    :unchecked-value="false"
                                    switch
                                  >
                                    Admin
                                  </b-form-checkbox>
                                </b-list-group-item>
                                <b-list-group-item>
                                  <b-form-checkbox
                                    v-model="user.claims.vip"
                                    :value="true"
                                    :unchecked-value="false"
                                    switch
                                  >
                                    VIP
                                  </b-form-checkbox>
                                </b-list-group-item>
                                <b-list-group-item>
                                  <b-form-checkbox
                                    v-model="user.claims.paid"
                                    :value="true"
                                    :unchecked-value="false"
                                    switch
                                  >
                                    Paid
                                  </b-form-checkbox>
                                </b-list-group-item>
                              </b-list-group>
                            </b-form-group>
                            <b-form-group
                              id="roles"
                              label="Roles"
                              label-for="roles"
                            >
                              <b-list-group id="roles">
                                <b-list-group-item
                                  class=""
                                  v-for="role in roles"
                                  :key="role['.key']"
                                >
                                  <b-form-checkbox
                                    v-model="user.roles"
                                    :name="'role-' + role.name"
                                    :value="role['.key']"
                                    switch
                                  >
                                    {{ role.name }}
                                  </b-form-checkbox>
                                  <p class="mx-1 my-1 text-muted">
                                    {{ role.description }}
                                  </p>
                                </b-list-group-item>
                              </b-list-group>
                            </b-form-group>
                          </div>
                        </b-tab>
                        <b-tab title="Preferences">
                          <div class="form-wrapper">
                            <b-form-group>
                              <b-form-checkbox
                                v-model="user.news_enroll"
                                name="news_enroll"
                                :value="true"
                                class="mb-3"
                                switch
                              >
                                Newsletter Enrollment
                              </b-form-checkbox>
                            </b-form-group>
                          </div>
                        </b-tab>
                        <template #tabs-end>
                          <li
                            role="presentation"
                            class="
                              nav-item
                              align-self-center
                              justify-content-end
                              text-right
                            "
                          >
                            <b-button
                              type="submit"
                              variant="success"
                              :disabled="saving"
                              size="sm"
                              class="mr-1"
                            >
                              <span v-if="saving">
                                <b-spinner small></b-spinner>
                                <span class="sr-only">Saving...</span>
                              </span>
                              <span v-else>
                                <i class="far fa-save"></i>
                              </span>
                            </b-button>
                            <b-button
                              variant="danger"
                              @click="closeEdit"
                              size="sm"
                              class="mr-1"
                              ><i class="far fa-window-close"></i
                            ></b-button>
                          </li>
                        </template>
                      </b-tabs>
                    </b-form>

                    <b-modal
                      id="confirmCreate"
                      title="Send Welcome Email?"
                      ok-title="Create"
                      @ok="create"
                    >
                      Do you want to send a welcome email to the new user?
                      <b-form-checkbox
                        v-model="user.sendWelcome"
                        :value="true"
                        :unchecked-value="false"
                        class="mt-2"
                      >
                        Send Welcome Email
                      </b-form-checkbox>
                    </b-modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ais-instant-search>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import Loader from "@/components/Loader";
import Users from "@/firebase/users";
import CustomInfiniteHits from "@/components/CustomInfiniteHits";
import algoliasearch from "algoliasearch/lite";

export default {
  name: "Users",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    loader: Loader,
    "infinite-hits": CustomInfiniteHits,
  },
  data() {
    return {
      bind: [{ ref: "roles", target: "roles" }],
      loading: true,
      saving: false,
      users: [],
      user: {},
      isNew: false,
      roles: [],
      fields: [
        {
          key: "auth.uid",
          label: "UID",
          sortable: false,
        },
        {
          key: "auth.displayName",
          label: "Name",
          sortable: true,
        },
        {
          key: "auth.email",
          label: "Email",
          sortable: true,
        },
        {
          key: "attributes",
          label: "Attributes",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      showEdit: false,
      searchClient: false,
      states: [
        {
          text: "Alabama",
          value: "AL",
        },
        {
          text: "Alaska",
          value: "AK",
        },
        {
          text: "American Samoa",
          value: "AS",
        },
        {
          text: "Arizona",
          value: "AZ",
        },
        {
          text: "Arkansas",
          value: "AR",
        },
        {
          text: "California",
          value: "CA",
        },
        {
          text: "Colorado",
          value: "CO",
        },
        {
          text: "Connecticut",
          value: "CT",
        },
        {
          text: "Delaware",
          value: "DE",
        },
        {
          text: "District Of Columbia",
          value: "DC",
        },
        {
          text: "Federated States Of Micronesia",
          value: "FM",
        },
        {
          text: "Florida",
          value: "FL",
        },
        {
          text: "Georgia",
          value: "GA",
        },
        {
          text: "Guam",
          value: "GU",
        },
        {
          text: "Hawaii",
          value: "HI",
        },
        {
          text: "Idaho",
          value: "ID",
        },
        {
          text: "Illinois",
          value: "IL",
        },
        {
          text: "Indiana",
          value: "IN",
        },
        {
          text: "Iowa",
          value: "IA",
        },
        {
          text: "Kansas",
          value: "KS",
        },
        {
          text: "Kentucky",
          value: "KY",
        },
        {
          text: "Louisiana",
          value: "LA",
        },
        {
          text: "Maine",
          value: "ME",
        },
        {
          text: "Marshall Islands",
          value: "MH",
        },
        {
          text: "Maryland",
          value: "MD",
        },
        {
          text: "Massachusetts",
          value: "MA",
        },
        {
          text: "Michigan",
          value: "MI",
        },
        {
          text: "Minnesota",
          value: "MN",
        },
        {
          text: "Mississippi",
          value: "MS",
        },
        {
          text: "Missouri",
          value: "MO",
        },
        {
          text: "Montana",
          value: "MT",
        },
        {
          text: "Nebraska",
          value: "NE",
        },
        {
          text: "Nevada",
          value: "NV",
        },
        {
          text: "New Hampshire",
          value: "NH",
        },
        {
          text: "New Jersey",
          value: "NJ",
        },
        {
          text: "New Mexico",
          value: "NM",
        },
        {
          text: "New York",
          value: "NY",
        },
        {
          text: "North Carolina",
          value: "NC",
        },
        {
          text: "North Dakota",
          value: "ND",
        },
        {
          text: "Northern Mariana Islands",
          value: "MP",
        },
        {
          text: "Ohio",
          value: "OH",
        },
        {
          text: "Oklahoma",
          value: "OK",
        },
        {
          text: "Oregon",
          value: "OR",
        },
        {
          text: "Palau",
          value: "PW",
        },
        {
          text: "Pennsylvania",
          value: "PA",
        },
        {
          text: "Puerto Rico",
          value: "PR",
        },
        {
          text: "Rhode Island",
          value: "RI",
        },
        {
          text: "South Carolina",
          value: "SC",
        },
        {
          text: "South Dakota",
          value: "SD",
        },
        {
          text: "Tennessee",
          value: "TN",
        },
        {
          text: "Texas",
          value: "TX",
        },
        {
          text: "Utah",
          value: "UT",
        },
        {
          text: "Vermont",
          value: "VT",
        },
        {
          text: "Virgin Islands",
          value: "VI",
        },
        {
          text: "Virginia",
          value: "VA",
        },
        {
          text: "Washington",
          value: "WA",
        },
        {
          text: "West Virginia",
          value: "WV",
        },
        {
          text: "Wisconsin",
          value: "WI",
        },
        {
          text: "Wyoming",
          value: "WY",
        },
      ],
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile", "config"]),
    ...mapGetters(["searchConfig"]),
    searchIndex() {
      return window.environment === "production"
        ? "prod_Users"
        : "sandbox_Users";
    },
    algoliaSearchConfig() {
      return this.searchConfig;
    },
  },
  watch: {
    // users: {
    //   immediate: true,
    //   handler(users) {
    //     users.forEach((user, index) => {
    //       if (
    //         !user.auth.customClaims ||
    //         typeof user.auth.customClaims !== "object"
    //       ) {
    //         this.users[index].auth.customClaims = {};
    //       }
    //       if (!user.db.profile || typeof user.db.profile !== "object") {
    //         this.users[index].db.profile = {};
    //       }
    //       if (!Array.isArray(user.db.roles)) {
    //         this.users[index].db.roles = [];
    //       }
    //     });
    //   },
    // },
    algoliaSearchConfig: {
      immediate: true,
      handler(config) {
        console.log("SearchConfig", config);
        if (typeof config.app_id === "string" && config.app_id.length > 0) {
          // Initialize Algolia
          this.searchClient = algoliasearch(config.app_id, config.search_key);
        }
      },
    },
  },
  methods: {
    edit(user) {
      this.isNew = false;
      this.user = { ...user };
      this.showEdit = true;
    },
    closeEdit() {
      this.user = {};
      this.showEdit = false;
    },
    newUser() {
      this.isNew = true;
      this.user = {};
      this.showEdit = true;
    },
    create() {
      this.saving = true;
      const user = this.prepareUserUpdate(this.user);
      console.log("User", user);
      const u = new Users();
      u.addUser(user)
        .then(() => {
          this.saving = false;
          this.closeEdit();
          this.notyf.success("User updated successfully.");
        })
        .catch((error) => {
          this.saving = false;
          this.notyf.error("An error occurred creating the user: " + error);
          console.error("Unable to create user", error);
        });
      return true;
    },
    prepareUserUpdate(user) {
      return {
        uid: user.objectID,
        auth: {
          email: user.email,
          password: user.password,
          customClaims: { ...user.claims },
        },
        db: {
          profile: {
            firstName: user.firstName,
            lastName: user.lastName,
            news_enroll: user.news_enroll,
            stripe_customer_id: user.stripe_customer_id,
          },
          roles: [...user.roles],
        },
        actions: {
          sendWelcome: user.sendWelcome,
        },
      };
    },
    update() {
      this.saving = true;
      const user = this.prepareUserUpdate(this.user);
      console.log("User", user);
      const u = new Users();
      u.updateUser(user.uid, user)
        .then(() => {
          this.saving = false;
          this.notyf.success("User updated successfully.");
        })
        .catch((error) => {
          this.saving = false;
          this.notyf.error("An error occurred updating the user: " + error);
          console.error("Unable to update user", user.uid, error);
        });
      return true;
    },
    deleteUser(uid) {
      this.saving = true;
      const u = new Users();
      u.deleteUser(uid)
        .then(() => {
          this.saving = false;
          this.notyf.success("User deleted successfully.");
          this.users.forEach((user, index) => {
            if (user.objectID === uid) {
              this.users.splice(index, 1);
            }
          });
        })
        .catch((error) => {
          this.saving = false;
          this.notyf.error("An error occurred deleting the user: " + error);
          console.error("Unable to delete user", uid, error);
        });
      return true;
    },
  },
  created() {},
};
</script>

<style scoped>
.card-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -4px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.background-none {
  background: none;
}

.profile-tab {
  max-width: 600px;
}
</style>
