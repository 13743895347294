<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="card shadow-sm">
          <div class="container-fluid">
            <div class="row">
              <div class="col text-left">
                <div class="card-body">
                  <h5 class="card-title">Submit Ticket</h5>
                  <p class="card-text"></p>
                </div>
              </div>
            </div>
          </div>
          <form id="frmAdd" action="#" method="post">
            <ul class="list-group list-group-flush">
              <li id="tab-content" class="list-group-item">
                <p>
                  Please describe the issue you are having as thoroughly as
                  possible.
                </p>
                <algolia-autocomplete
                  display="fullName"
                  @change="updateSender"
                  placeholder="Sender"
                  class="mb-3"
                ></algolia-autocomplete>
                <input
                  id="inpNewTicketSubject"
                  type="text"
                  class="form-control mb-3"
                  placeholder="Subject"
                  v-model="ticket.subject"
                />
                <text-editor
                  v-model="ticket.message"
                  placeholder="Thorough description..."
                  class="mb-3"
                  preset="basic"
                ></text-editor>
                <b-form-checkbox
                  v-model="assignToMe"
                  :value="true"
                  :unchecked-value="false"
                >
                  Assign to me
                </b-form-checkbox>
              </li>
              <li class="list-group-item">
                <b-button variant="primary" @click="submit">
                  <b-spinner small v-if="processing"></b-spinner>
                  <span v-if="!processing"
                    ><i class="far fa-save"></i> Submit</span
                  ></b-button
                >
              </li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import Ticket from "@/firebase/tickets";
import AlgoliaAutocomplete from "@/components/AlgoliaAutocomplete";
import TextEditor from "@/components/TextEditor";

export default {
  name: "SubmitTicket",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  components: { AlgoliaAutocomplete, TextEditor },
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    slug: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      ref: "tickets",
      bind: [],
      ticket: {
        sender: {},
        subject: "",
        message: "",
      },
      users: [],
      assignToMe: false,
      processing: false,
    };
  },
  watch: {
    users: {
      immediate: true,
      handler(users) {
        users.forEach((user, index) => {
          if (
            !user.auth.customClaims ||
            typeof user.auth.customClaims !== "object"
          ) {
            this.users[index].auth.customClaims = {};
          }
          if (!user.db.profile || typeof user.db.profile !== "object") {
            this.users[index].db.profile = {};
          }
          if (!Array.isArray(user.db.roles)) {
            this.users[index].db.roles = [];
          }
        });
      },
    },
    assignToMe: {
      immediate: true,
      handler(assignToMe) {
        if (assignToMe) {
          this.ticket.assigned = {
            uid: this.userProfile.uid,
            name: this.userProfile.name,
            avatar: this.userAvatar,
          };
        } else {
          delete this.ticket.assigned;
        }
      },
    },
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    ...mapGetters(["userAvatar"]),
  },
  methods: {
    submit() {
      this.processing = true;
      const t = new Ticket();
      t.submit(this.ticket)
        .then(() => {
          this.notyf.success("Ticket submitted successfully.");
          if (this.id !== null) {
            this.navigate("helpdesk-shared", { id: this.id });
          } else {
            this.navigate("helpdesk-inbox");
          }
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(
            "An error occurred while submitting the ticket. Please try again later."
          );
          this.processing = false;
        });
    },
    updateSender(value) {
      const user = { ...value };
      this.ticket.sender = {
        uid: user.objectID,
        name: user.firstName + " " + user.lastName,
        avatar: user.avatar,
      };
      if (user.claims.admin) {
        this.ticket.sender.type = "Administrator";
      } else if (user.claims.paid) {
        this.ticket.sender.type = "Paid Member";
      } else {
        this.ticket.sender.type = "Free Member";
      }
    },
  },
};
</script>

<style>
.sender-input {
  height: calc(1.5em + 0.75rem + 2px);
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.sender-input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.sender-result-list {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  list-style-type: none;
  cursor: pointer;
}

.sender-result-list:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
</style>
